
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
export const configJSON = require("./config");
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web";
// Customizable Area End

export interface Props extends RouteComponentProps<any> {

  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  history: any;
  RouteComponentProps: any;
  location: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  imageHolder: any;
  show: boolean;
  bookId: any,
  notification: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FlipControllerController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getImageApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      imageHolder: null,
      notification: false,
      show: false,
      bookId: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

  }

  componentDidMount = async () => {
    // Customizable Area Start
    
    GoogleAnalytics()
    
    this.getImageRequest();
    // Customizable Area End
  }
    // Customizable Area Start

  getImageRequest = () => {
    console.log('getImagesApi start')
    const header = {
      "Content-Type": configJSON.imageApiContentType,

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_book/books/${this.props.location.state.bookId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("getImagesApi end")
  };
    // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("receive function==>")
    runEngine.debugLog("Message Recivedyyyyy", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getImageApiCallId != null &&
      this.getImageApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        console.log("response==>", responseJson.data)

        this.setState({ imageHolder: responseJson.data });
        this.setState({ bookId: responseJson.data.id });

        runEngine.debugLog("imageHolder", this.state.imageHolder);

      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }

      const imgArr = this.state.imageHolder.attributes.images;
    }
    // Customizable Area End
  }
}