Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";

exports.hardcoverPrice = "1500";
exports.softcoverPrice = "1275";
exports.coverType = ["soft cover", "hard cover"];
exports.paperType = ["glossy", "matte"];
exports.bookSize = "8 * 8 inch";

exports.getbookPriceEndpoint = "bx_block_book/books/get_book_price";
exports.getbookPriceContentType = "application/json";
exports.getbookPriceMethodType = "GET";

exports.getbookColorEndpoint = "bx_block_book/books/book_color";
exports.getbookColorContentType = "application/json";
exports.getbookColorMethodType = "GET";

exports.CheckoutEndpoint = "bx_block_order_management/orders/create_order";
exports.CheckoutContentType = "application/json";
exports.CheckoutMethodType = "POST";

exports.CheckoutPutEndpoint = "bx_block_order_management/orders/";
exports.CheckoutPutMethodType = "PUT";

exports.GetOrderDetailsContentType = "application/json";
exports.GetOrderDetailsEndPoint = "bx_block_ordersummary/order_summary/get_order_details";
exports.GetOrderDetailsGetMethodType = "GET";

// Customizable Area End
