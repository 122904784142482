// Customizable Area Start
import React from 'react';
import { Grid, Button } from "@material-ui/core";
import HomeScreenController from './HomeScreenController.web';
import { logoImg, landingMobImg, reviewImg, star, bookImg, yellowStar, aboutUs, rightArrow, qualityMark, menu } from "./assets";
import "./index.css";
import StorageProvider from "../../../framework/src/StorageProvider";
import Footer from './Footer.web';
import { withRouter } from "react-router";
import Menu from "./Menu.web";
import StarIcon from '@material-ui/icons/Star';

import LandingPageNew from "./LandingPage.web"



class HomeScreen extends HomeScreenController {

    async componentDidMount() {
  
        console.log("storage Provider", StorageProvider)
        
        let authTokenData = await StorageProvider.get('authToken')
        if (authTokenData) {
            authTokenData = JSON.parse(authTokenData);
            console.log(authTokenData)
            this.setState({
                authToken: authTokenData.token,

            });
            console.log(this.state.authToken)
        }
    }

    sighin = async () => {
        await StorageProvider.get('authToken');
        window.location.href = "";
    }


    render() {

        return (
            <>
                {/* <Grid container direction="row" justifyContent="flex-end">
                    <img src={menu} width="40" />
                </Grid> */}

                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }} >

                    <Grid md={12} container direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: "20px" }} >
                        <Grid style={{ position: "relative" }}>
                            <img src={logoImg} width="200" />

                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end" style={{ position: "absolute" }}>
                            <div style={{ cursor: 'pointer' }} className="dropdown" >
                                <span><img src={menu} onClick={() => { this.props.history.push("Menu") }} width="40" style={{ padding: "15px", marginTop: "15px" }} /></span>
                                {/* {this.state.openMenu &&  <Menu {...this.props}/>} */}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="space-around">
                        <Grid item md={12}>
                            <h1
                                style={{ textAlign: "center", fontSize: "clamp(18px, 6vw, 28px)", padding: "1.2em", fontFamily: "inherit", fontWeight: "bold", margin: "auto" }}><span style={{ display: "block" }}>Gather All the pictures of</span> <span style={{ display: "block" }}>yours kids and</span> <span style={{ display: "block" }}>loved once in <span style={{ fontStyle: "italic" }} className="txt-blue">Photo Books</span></span>
                            </h1>
                        </Grid>
                        <Grid item md={12}>
                            <div style={{ textAlign: "center", fontSize: "1.1em", padding: "0.2em", margin: "0px 10px 5px 30px" }}
                            >Because great memories are worth printing!</div>

                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{}}>
                        <Button
                            onClick={() => { this.props.history.push("SelectOption") }}
                            className="textCapitalize btn-rounded bg-green btn-checkout"
                            size="large"
                            variant="contained"
                            color="primary"
                            style={{ width: "40%", marginRight: "20px", marginTop: "20px" }}
                        >
                            Let's Create
                        </Button>
                    </Grid>
                    {/* <Grid container direction="row" justifyContent="flex-end" style={{ marginRight: "20px", padding: "0.5em" }}>
                        <Button
                            onClick={() => { this.props.history.push("SelectOption") }}
                            className="textCapitalize btn-rounded bg-green btn-checkout"
                            size="large"
                            variant="contained"
                            color="primary"
                        >
                            Let's Create
                        </Button>
                        <div className="bg-green" style={{ color: 'white', borderRadius: '8px', padding: '12px', fontWeight: "bold", width: "120px", textAlign: "center" }}
                            onClick={() => { this.props.history.push("SelectOption") }}
                        >
                            Let's Create
                        </div>
                    </Grid> */}

                    <Grid container direction="row" justifyContent="center" className="demo">
                        <Grid item md={12} xs={12} sm={12} lg={6} style={{ textAlign: 'center' }}>
                            <div style={{ margin: "40px auto" }}>
                                <img src={landingMobImg} width="300" />
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12} lg={6} style={{ textAlign: "center" }}>
                            <div style={{ backgroundColor: "#ccc", width: '250' }} className="video">
                                Video
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item md={12} >
                            <div style={{ textAlign: "center", lineHeight: '1.5', padding: "1.2em" }}>
                                <h3>We create your photo book by taking care of your stories and memories in the best way possible.</h3>
                                <h3>If you haven't already, now's the time to give a whirl. We promise you won't be disaapointed!</h3>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container >

                        <Grid item md={12} >
                            <Grid container justifyContent="center" style={{ height: '100%' }}>

                                <Grid item md={6} className="photobook" style={{ marginBottom: "10px" }}>
                                    <div style={{ color: "white", textAlign: 'center', padding: '1.5em' }} className="homeBack" >
                                        <h2 style={{ marginTop: "0", marginBottom: "10px" }}>Love from Whitebook</h2>
                                        <span style={{ fontSize: "medium" }}>With over 100,000 5-star reviews, the whitebook is the hands-down easiest<br /> way to make a photo book, straight<br /> from your phone. </span>
                                    </div>
                                </Grid>

                                <Grid item md={6} >
                                    <div >
                                        <img src={reviewImg} width="800" className="responsive" />
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ margin: "30px 0px", fontFamily: "inherit" }}>
                        <Grid item md={6} xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                            <div style={{ paddingBottom: "0px" }}>
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />

                                {/* <img src={star} width="20" ></img>
                                <img src={star} width="20"></img>
                                <img src={star} width="20"></img>
                                <img src={star} width="20"></img>
                                <img src={star} width="20"></img> */}
                            </div>
                            <div style={{ fontFamily: "inherit" }}>
                                <b>"Loved how it turned out!!"</b>
                                <br />
                                <b style={{ fontStyle: "italic", paddingTop: "10px", paddingLeft: "80px" }}>-Monika Sharma</b>
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: "30px" }} >
                            <div style={{ paddingBottom: "0px" }}>
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                                <StarIcon className="txt-green" style={{ fontSize: "28px" }} />
                            </div>
                            <div>
                                <b>"Mom of toodler approved!!"</b>
                                <br />
                                <b style={{ fontStyle: "italic", paddingTop: "10px", paddingLeft: "100px" }}>-Akash Sharma</b>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-around" className="homeBack" style={{ width: "100%", paddingBottom: '4%', paddingTop: '4%', color: 'white', fontFamily: "inherit" }}>
                        <div style={{ display: 'inline-block', padding: "15px" }}>Spill Proof</div>
                        <div style={{ display: 'inline-block', padding: "15px" }}>Fade Proof</div>
                        <div style={{ display: 'inline-block', padding: "15px" }}>Automatic</div>
                        <div style={{ display: 'inline-block', padding: "15px" }}>Tear Resistant</div>
                        <div style={{ display: 'inline-block', padding: "7.5px" }}>Zero Formatting</div>

                    </Grid>

                    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ fontFamily: "inherit" }}>
                        <Grid item xs={12} md={12} style={{ textAlign: 'center', marginTop: "1.3em" }}>
                            <h2>Customize your book in minutes</h2>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ textAlign: 'center', fontSize: "1em", padding: "0px 15px 5px 15px" }}>
                            <p style={{ marginTop: "0px" }}>Easily move the picture, add a title, and choose cover design , And unlike photo album,you create a photo book once and can order copies for everyone.</p>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <img src={bookImg} width="100" height="100" className="responsive book-image-center"></img>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{}}>
                        <Button
                            onClick={() => { this.props.history.push("SelectOption") }}
                            className="textCapitalize btn-rounded bg-green btn-checkout"
                            size="large"
                            variant="contained"
                            color="primary"
                            style={{ width: "40%", marginRight: "20px", marginTop: "20px" }}
                        >
                            Let's Create
                        </Button>
                    </Grid>

                    <Grid md={12} container direction="row" justifyContent="space-around" alignItems="center" style={{ paddingTop: "20px" }} >
                        <Grid container direction="row" style={{ position: "relative", marginLeft: "1.5em" }}>
                            <img src={yellowStar} width="120" />

                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end" style={{ position: "absolute" }}>
                            <div className="super-fastText">
                                <p><b style={{ fontSize: "1.2em", color: "green" }} >Super Fast And Easy</b></p>
                                <p style={{ paddingLeft: '40px', fontSize: "1.2em", color: "green" }}><b>High Quality Images</b></p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container style={{ color: 'green' }} justifyContent="center">
                        <Grid item md={6} style={{ textAlign: 'right' }}>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="center">
                        <Grid item md={12} style={{ textAlign: 'center' }}>
                            <h1>Our Story</h1>
                        </Grid>
                        <br />
                        <Grid md={12} style={{ textAlign: 'center' }}>
                            <img src={aboutUs} style={{ width: "90%" }} className="responsive"></img>
                        </Grid>
                        <Grid md={12} style={{ textAlign: 'center', fontFamily: "inherit", padding: '5px' }}>
                            <div>
                                <p style={{ padding: "0px 20px" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis enim ea nostrum modi ratione laborum similique, aliquam pariatur quod ut odit eos possimus! Ea
                                </p>
                                <p style={{ fontStyle: "italic", textAlign: 'right', marginRight: "25px" }}>-Lo sed do eiusmod</p>
                            </div>
                        </Grid>
                        <Grid md={12} style={{ textAlign: 'center', marginTop: "10px" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <span style={{ color: 'green', fontSize: '20px' }}>
                                    <b>
                                        Get to Know Us
                                    </b>
                                </span>
                                <img src={rightArrow} style={{ paddingLeft: "10px" }} width="30" />
                            </div>

                        </Grid>

                        <Grid container >
                            <Grid item md={12} style={{ marginTop: "30px" }} >
                                <img src={qualityMark} className="responsive"></img>
                            </Grid>
                            <Grid item md={12} >
                                <p style={{ fontSize: '20px', textAlign: 'center', padding: "1em 1.3em", fontFamily: "inherit" }}>
                                    If you are not happy with how your books prints, ship, feels, smells or anything in between, we'll reprint or refund you.
                                </p>
                            </Grid>
                            <Grid item md={12} >
                                <h2 style={{ textAlign: 'center', padding: "10px" }}> Get your best Memories Delivered </h2>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{}}>
                        <Button
                            onClick={() => { this.props.history.push("SelectOption") }}
                            className="textCapitalize btn-rounded bg-green btn-checkout"
                            size="large"
                            variant="contained"
                            color="primary"
                            style={{ width: "40%", marginRight: "20px", marginBottom: "20px" }}
                        >
                            Let's Create
                        </Button>
                    </Grid>
                    <Footer {...this.props} />
                </Grid>
            </>

        )
    }
}

export default withRouter(HomeScreen)

// Customizable Area End