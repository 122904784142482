import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
//@ts-ignore
import { gapi } from "gapi-script";

const configJSON = require("./config");

export class GoogleWebDelegate {
  googleUserStatusChanged(userInfo: any, isRegistration: boolean): void { }
}


class GoogleWebController {
  googleUser: any;
  googleUserInfo: any;
  delegateClass: GoogleWebDelegate;


  static instance = new GoogleWebController();

  constructor() {

    this.loadAuth2();
    this.googleLogIn = this.googleLogIn.bind(this);
    this.updateGoogleUser = this.updateGoogleUser.bind(this);
  }

  ///////////////////////////////////
  // Customizable Area Start
  // GooGle DESKTOP Methods

  loadAuth2() {
    //@ts-ignore
    // window.gapi.load("auth2", () => {
    //   // @ts-ignore
    //   window.gapi.auth2.init({
    //     client_id: configJSON.clientID,
    //     scope: 'https://www.googleapis.com/auth/photoslibrary.readonly',
    //   })
    localStorage.setItem('mod', 'mod');
    if (localStorage.getItem('mod') != null) {

      localStorage.removeItem('mod');
    } else {
      alert('please enable Local Storage');
    }
    window.gapi.load("client", () => {
      //@ts-ignore
      window.gapi.client.init({
        "apiKey": "AIzaSyDuWIVcznBbEFGudauFx-rXdHvjPTFzqds",
        'clientId': configJSON.clientID,
        "scope": 'https://www.googleapis.com/auth/photoslibrary.readonly',

      })
        .then(() => {
          console.log('Cookie Enabled!')
          localStorage.setItem('isCookieEnabled', "true");
        }
        )
        .catch((e: any) => {
          console.log('error: ', e)
          localStorage.setItem('isCookieEnabled', "false");
          // alert("Please Enable third party cookies!")
        });
    });
  }
  // Customizable Area End


  signinChanged(val: any) {
    runEngine.debugLog("Signin state changed to ", val);
  }

  userChanged(user: any, isRegistration: boolean) {
    runEngine.debugLog("USER NOW: ", user);
    console.log("access_token", user.xc.access_token)
    // StorageProvider.set("access_token", user.xc.access_token.toString() );
    localStorage.setItem("access_token", user.xc.access_token.toString())

    if (GoogleWebController.instance.updateGoogleUser(user)) {
      GoogleWebController.instance.delegateClass.googleUserStatusChanged(
        GoogleWebController.instance.googleUserInfo,
        isRegistration
      );
    }
  }


  googleLogIn(delegateClass: any) {
    try {
      const auth2 = gapi.auth2.getAuthInstance();

      GoogleWebController.instance.googleUser = null;
      GoogleWebController.instance.delegateClass = delegateClass;
      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(GoogleWebController.instance.signinChanged);
      //@ts-ignore
      auth2.currentUser.listen(GoogleWebController.instance.userChanged);

      return auth2.signIn();
    } catch (error) {
      runEngine.debugLog(error);
    }
  }

  updateGoogleUser(googleUser: any) {
    if (googleUser && googleUser !== GoogleWebController.instance.googleUser) {
      GoogleWebController.instance.googleUser = googleUser;
      var auth2 = gapi.auth2.getAuthInstance();

      var profile = auth2.currentUser.get().getBasicProfile();

      if (profile) {
        var userEmail = profile.getEmail();
        var userId = profile.getId();
        console.log("profile", profile)
        runEngine.debugLog(userEmail + "_______" + userId);


        GoogleWebController.instance.googleUserInfo = {
          email: userEmail,
          id: userId
        };

        return true;
      }
    }

    return false;
  }
}

const googleController = new GoogleWebController();
export default googleController;
