Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Gallery";
exports.labelBodyText = "Gallery Body";

exports.btnExampleTitle = "CLICK ME";

exports.thresholdResolution = 300
exports.totalImageSize = 20
exports.default_book_color = "#fff"
exports.default_title_color = "black"
exports.default_logo_color = "#8da4ee"
exports.errorShareButton = "Unsupported share feature";

// default_book_color = "#F0F0F0"

exports.imageApiContentType = "multipart/form-data";
exports.apiMethodTypeGet = "GET";
exports.imageAPiEndPoint = `bx_block_book/books/100`;

exports.apiUploadImageEndPoint = "bx_block_book/books";
exports.apiUploadImageContentType = "application/json";
exports.apiUploadImageMethod = "POST";

exports.googlePhotosApiContentType = "application/json";

exports.apiPreSignedEndPoint = 'bx_block_book/presigned_uploads';
exports.apiPreSignedMethod = 'POST';
exports.apiPreSignedContentType = "application/json; charset=UTF-8";

exports.apiBookCreateEndPoint = 'bx_block_book/books/book_create_new'
exports.apiBookCreateMethod = 'POST'
exports.apiBookCreateContentType = "application/json; charset=UTF-8"

exports.apiBookUpdateEndPoint = 'bx_block_book/books/book_update_new'
exports.apiBookUpdateMethod = 'PUT'
exports.apiBookUpdateContentType = "application/json; charset=UTF-8"

exports.prodFrontURL = "//whitebook.co.in/"
exports.devFrontURL = "https://snapslikeapp2-89023-react-native.b89023.dev.eastus.az.svc.builder.cafe"
exports.stageFrontURL = "https://snapslikeapp2-89023-react-native.b89023.stage.eastus.az.svc.builder.ai"
exports.preProductMinioEndPoint = "minio.b89023.uat.eastus.az.svc.builder.ai"
exports.devMinioEndPoint = "minio.b89023.dev.eastus.az.svc.builder.cafe"
exports.stageMinioEndPoint = "minio.b89023.stage.eastus.az.svc.builder.ai"
exports.prodMinioEndPoint = "minio.b89023.prod.centralindia.az.svc.builder.ai"
exports.minioAccessKey = "hello"
exports.minioSecretKey = "builderai"
exports.bucketName = "sbucket"

// exports.apiMinioEndPoint = 'bx_block_book/presigned_uploads';
exports.apiMinioMethod = 'PUT';
// exports.apiMinioContentType = "application/json";

exports.apiGetUserLinkEndPoint = "bx_block_book/books/get_user_link"
exports.apiGetUserLinkMethodType = 'GET'
exports.apiGetUserLinkContentType = "application/json";

exports.apiGetImageLimitEndPoint = "bx_block_book/books/get_image_limit"
exports.apiGetImageLimitMethodType = 'GET'
exports.apiGetImageLimitContentType = "application/json";

exports.apiGetBookPriceEndPoint = "bx_block_book/books/get_book_price";
exports.apiGetBookPriceMethodType = "GET";
exports.apiGetBookPriceContentType = "application/json";

exports.apiGetPagePriceEndPoint = "bx_block_book/books/get_additional_price_per_page"
exports.apiGetPagePriceMethodType = "GET"
exports.apiGetPagePriceContentType = "application/json"

exports.apiContributeImageEndPoint = 'bx_block_book/books/start_contributing_images'
exports.apiContributeImageMethodType = 'POST'
exports.apiContributeImageContentType = "application/json"

exports.apiGetContributedImageEndPoint = 'bx_block_book/books/get_contributed_images'
exports.apiGetContributedImageMethodType = 'POST'
exports.apiGetContributedImageContentType = "application/json"

exports.CreateOrderEndpoint = "bx_block_order_management/orders/create_order";
exports.CreateOrderContentType = "application/json";
exports.CreateOrderMethodType = "POST";

// Customizable Area End