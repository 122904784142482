import React from 'react';
// Customizable Area Start
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import EmailAccountSignUpController from './EmailAccountSignUpController.web';
import withSnackBar from "../../../components/src/withSnackBar.Web";
import Popup from "../../../components/src/Popup.web";
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
export const configJSON = require("./config");


export class EmailSignUp extends EmailAccountSignUpController {

    popupMessage = (successMsg: string, errorMsg: string) => {
        if(successMsg) return `${successMsg}`;
        if(errorMsg) return `${errorMsg}`;
        return `${successMsg}`;
    }

    signUpEmailBtn = (isButtonDisabled: boolean) => {

        return(
            isButtonDisabled ?
            <Button
                className="textCapitalize btn-rounded btn-checkout bg-disabled"
                size="medium"
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                data-testId={"verifyEmailOtp"}
            >
                Sign Up
            </Button>
            :
            <Button
                id="SignUp"
                onClick={this.verifyEmailOtp}
                className={!this.state.otpEmail ? "textCapitalize btn-rounded btn-checkout bg-disabled" : "textCapitalize btn-rounded bg-green btn-checkout"}
                size="medium"
                variant="contained"
                color="primary"
                disabled={!this.state.otpEmail}
                data-testId={"verifyEmailOtp"}
            >
                Sign Up
            </Button>
        )
    }

    signUpMobileBtn = (isButtonDisabled: boolean, otpMobile: any) => {
        return(
            isButtonDisabled ?
            <Button
                className="textCapitalize btn-rounded btn-checkout bg-disabled"
                size="medium"
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                data-testId={"verifyPhoneOtp"}
            >
                Sign Up
            </Button>
            :
            <Button
                onClick={ () => this.setState({ isButtonDisabled: !isButtonDisabled }, this.verifyMobileOtp )}
                className={!otpMobile ? "textCapitalize btn-rounded btn-checkout bg-disabled" : "textCapitalize btn-rounded bg-green btn-checkout"}
                size="medium"
                variant="contained"
                color="primary"
                disabled={!otpMobile}
                data-testId={"verifyPhoneOtp"}
            >
                Sign Up
            </Button>
        )
    }

    getEmailOtpBtn = (isButtonDisabled: boolean, isValid: boolean, fn: any) => {
        return(
            isButtonDisabled ?
            <Button
                className="textCapitalize btn-rounded btn-checkout bg-disabled"
                size="medium"
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                data-testId={"getEmailOtp"}
            >
                Get An OTP
            </Button>
            :
            <Button
                onClick={fn}
                className={!isValid ? "textCapitalize btn-rounded btn-checkout bg-disabled" : "textCapitalize btn-rounded bg-green btn-checkout"}
                size="medium"
                variant="contained"
                color="primary"
                disabled={!isValid}
                data-testId={"getEmailOtp"}
            >
                Get An OTP
            </Button>
        )
    }

    getMobileOtpBtn = (isButtonDisabled: boolean, isValid: boolean) => {
        return(
            isButtonDisabled ?
            <Button
                className="textCapitalize btn-rounded btn-checkout bg-disabled"
                size="medium"
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                data-testId={"getPhoneOtp"}
            >
                Get An OTP
            </Button>
            :
            <Button
                onClick={this.getMobileOtp}
                className={!isValid ? "textCapitalize btn-rounded btn-checkout bg-disabled" : "textCapitalize btn-rounded bg-green btn-checkout"}
                size="medium"
                variant="contained"
                color="primary"
                disabled={!isValid}
                data-testId={"getPhoneOtp"}
            >
                Get An OTP
            </Button>
        )
    }

    emailInputSection = (isValid: boolean, invalidMessage: string, emailOtpTimer: any, isButtonDisabled: boolean) => {
        return(
            <Grid container justifyContent="center" alignItems="center">
                <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                    <br />
                    <b>Email</b>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: "10px" }}>
                    <TextField
                        margin="normal"
                        placeholder={ configJSON.placeHolderEmailWeb }
                        variant="outlined"
                        fullWidth
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        style={{ borderRadius: '50px', textTransform: "lowercase" }}
                        inputProps={{
                            "data-testId": "inputEmail"
                        }}
                    />
                    <div className={`message ${!isValid ? 'error' : 'success'}`}>
                        {this.state.email === "" ? "" : invalidMessage}
                    </div>
                </Grid>
                {
                    this.state.emailOtpSent ?
                        <>
                            <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <b>OTP</b>
                                    <div style={{ marginRight: '20px', fontSize: "12px", textAlign: 'right' }}>
                                        <Button onClick={() => this.setState({emailOtpSent: true, emailOtpTimer: 90}, this.getEmailOtp)} disabled={emailOtpTimer > 0} style={{ color: emailOtpTimer === 0 ? 'blue' : 'gray', textTransform: "capitalize", padding: '0' }}>Resend OTP</Button>
                                        <br />
                                        {emailOtpTimer > 0 && `in ${new Date(emailOtpTimer * 1000).toISOString().substring(14, 19)}s`}
                                    </div>
                                </div>
                            </Grid>
                            <Grid container md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '10px' }}>
                                <TextField
                                    style={{ textTransform: "lowercase" }}
                                    margin="normal"
                                    placeholder={ configJSON.placeHolderOTP }
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.otpEmail}
                                    onChange={this.handleEmailOtpChange}
                                    inputProps={{ borderRadius: "50px", "data-testId": "inputEmailOTP" }}
                                />
                                <Typography variant='body2' style={{ fontSize: '11px' }}>Please check Spam folder If you are not getting OTP in inbox</Typography>
                            </Grid>
                        </>
                        : null
                }
                <Grid item md={12} xs={12} style={{ textAlign: 'center' }} >
                    {
                        this.state.emailOtpSent ? this.signUpEmailBtn(isButtonDisabled) : this.getEmailOtpBtn(isButtonDisabled, isValid, this.getEmailOtp)
                    }
                </Grid>
            </Grid>
        )
    }

    phoneNumberInputSection = (isValid: boolean, invalidMessage: string, mobileOtpTimer: any, isButtonDisabled: boolean) => {
        return(
            <Grid container justifyContent="center" alignItems="center">
                <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                    <br />
                    <b>Phone Number</b>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: "10px" }}>
                    <TextField
                        margin="normal"
                        placeholder={ configJSON.placeHolderPhoneNumber }
                        variant="outlined"
                        fullWidth
                        value={this.state.mobileNumber}
                        onChange={this.handleMobileNumberChange}
                        style={{ borderRadius: '50px', textTransform: "lowercase" }}
                        InputProps={{
                            startAdornment: 
                            <InputAdornment position="start">
                                <span style={{color:"#000"}}>+91</span>
                            </InputAdornment>,
                            inputProps: { maxLength: 10, "data-testId" : "inputPhone" }
                        }}
                    />
                    <div className={`message ${!isValid ? 'error' : 'success'}`}>
                        {this.state.mobileNumber === "" ? "" : invalidMessage}
                    </div>
                </Grid>
                {
                    this.state.mobileOtpSent &&
                        <>
                            <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <b>OTP</b>
                                    <div style={{ marginRight: '20px', fontSize: "12px", textAlign: 'right' }}>
                                        <Button onClick={() => this.setState({mobileOtpSent: true, mobileOtpTimer: 90}, this.getMobileOtp)} disabled={mobileOtpTimer > 0} style={{ color: mobileOtpTimer === 0 ? 'blue' : 'gray', textTransform: "capitalize", padding: '0' }}>Resend OTP</Button>
                                        <br />
                                        {mobileOtpTimer > 0 && `in ${new Date(mobileOtpTimer * 1000).toISOString().substring(14, 19)}s`}
                                    </div>
                                </div>
                            </Grid>
                            <Grid container md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '10px' }}>
                                <TextField
                                    style={{ textTransform: "lowercase" }}
                                    margin="normal"
                                    placeholder={ configJSON.placeHolderOTP }
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.otpMobile}
                                    onChange={this.handleMobileOtpChange}
                                    inputProps={{ borderRadius: "50px", "data-testId": "inputPhoneOTP" }}
                                />
                            </Grid>
                        </>
                }
                <Grid item md={12} xs={12} style={{ textAlign: 'center' }} >
                    {
                        this.state.mobileOtpSent ?
                            this.signUpMobileBtn(isButtonDisabled, this.state.otpMobile)
                            :
                            this.getMobileOtpBtn(isButtonDisabled, isValid)
                    }
                </Grid>
            </Grid>
        )
    }

    render() {

        const { open, successMessage, errorMessage, isValid, invalidMessage, emailOtpTimer, mobileOtpTimer, isButtonDisabled } = this.state;

        return (
            <>
                <Grid container direction="row" justifyContent="center">
                <Popup
                    style={{ top: "3%", left: successMessage ? "20%" : "5%", width: successMessage ? "60%" : "90%", bottom:"auto" }}
                    flag={open}
                    handleClose={() => this.setState({ open: false })}
                    massage={this.popupMessage(successMessage, errorMessage)}
                />
                    <span
                        style={{ fontSize: '1.1em', cursor: 'pointer', position: "absolute", top: "20px", left: "20px" }}
                        onClick={() => this.props.location.state?.path == '/FlipBook' ? 
                            this.props.history.push({ pathname: '/Flipbook', state: this.props.location.state }) : 
                            this.props.history.push('/')}
                        ><ArrowBackIos /></span>
                    <Grid item md={12} style={{ textAlign: 'center', marginTop: "40px" }}>
                        <h2>Sign Up To Your Whitebook Account</h2>
                    </Grid>
                    <p style={{ fontSize: '20px', margin: "0px 30px 0px 30px", textAlign: "center" }}>Record the life of your family. </p>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item md={12} xs={12}>
                        {
                            !this.state.isEmailOTPVerified ?
                            this.emailInputSection(isValid, invalidMessage, emailOtpTimer, isButtonDisabled)
                               :
                            this.phoneNumberInputSection(isValid, invalidMessage, mobileOtpTimer, isButtonDisabled)
                        }
                    </Grid>
                    <Grid item md={12} xs={12} style={{ textAlign: 'center', marginTop: "15px" }}>
                        <b>OR</b>
                    </Grid>
                    <Grid item md={12} style={{ textAlign: 'center' }}>
                        <p style={{ marginTop: "15px" }}><b>Already have an account?
                            &nbsp;<span style={{ textDecoration: 'underLine', cursor: 'pointer' }} onClick={() => { 
                                this.props.history.push({
                                    pathname: 'EmailSignIn',
                                    state: this.props?.location?.state
                                  })
                             }}>
                                Sign In</span></b></p>
                    </Grid>
                        <br />
                        <br />
                </Grid>
            </>

        )
    }
}

const EmailAccountSignUpToast = withSnackBar(EmailSignUp);
export default withRouter(EmailAccountSignUpToast);
// Customizable Area End