import React from 'react';
// Customizable Area Start
import { Grid, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router";
import ReturnRefundController from "./ReturnRefundController.web";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import "./CMS.css";
import ReactGA from "react-ga4";
import Loader from '../../../components/src/Loader.web';
const configJSON = require("./config");


class ReturnRefundPolicy extends ReturnRefundController {


    render() {

        const htmlString = `${this.state.returnAndRefund}`

        return (
            <>
                <Grid container direction="row" justifyContent="center" style={{ marginTop: "0px", paddingTop: "54px", fontFamily: "inherit" }}>
                    <Grid item xs={12} lg={7} md={12} sm={12} style={{ marginBottom: "0px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", background: "#ffffff", position: "fixed", top: 0, zIndex: 2, padding: "15px 0" }}>
                        <div style={{ fontSize: '1.2em', cursor: 'pointer', position: "absolute", left: "0", marginLeft: "15px" }} onClick={() => { this.props.history.push("/Menu") }} >
                            <ArrowBackIos />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ margin: "0", fontSize: "20px", fontFamily: "inherit" }}>Return &amp; Refund Policy</h3>
                        </div>
                    </div>
                </Grid>
                    <Grid item xs={12} lg={7} md={12} sm={12}>
                        {this.state.returnAndRefund === null ? <Loader loading={true}/> : <div className="ql-editor"><div dangerouslySetInnerHTML={{ __html: htmlString }} /></div>}
                    </Grid>

                    {/* <Grid item xs={12} lg={7} md={12} sm={12}>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            Welcome  to mation, resources, services, products  and tools we provide,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)
                            ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                            policy content section below for more information).
                        </p>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            Welcome  to mation, resources, services, products  and tools we provide,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)
                            ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                            policy content section below for more information).
                        </p>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            You can either call us at <span style={{ color: "#6705A5", fontWeight: "bolder", textDecoration: "underline" }}>
                                6364 182 702
                            </span> or email at <span style={{ color: "#6705A5", fontWeight: "bolder", textDecoration: "underline" }}>
                                varahome@outlook.com</span>
                        </p>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            1.) Welcome  to mation , resources ,services , products  and tools we provide ,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)
                            ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                            policy content section below for more information).
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={7} md={12} sm={12}>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            2.)Welcome  to mation , resources ,services , products  and tools we provide ,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)
                            ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                            policy content section below for more information).
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={7} md={12} sm={12}>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            3.)Welcome  to mation , resources ,services , products  and tools we provide ,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)
                            ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                            policy content section below for more information).
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={7} md={12} sm={12}>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            4.)Welcome  to mation , resources ,services , products  and tools we provide ,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)
                            ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                            policy content section below for more information).
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={7} md={12} sm={12}>
                        <p style={{ textAlign: "justify", fontSize: "1em" }}>
                            Welcome  to mation , resources ,services , products  and tools we provide ,
                            you understand and adhere to the folowing terms & conditions as stated to the following terms and
                            condition as  stated in this policy .(here after  referred to as user aggrement)

                        </p>
                    </Grid> */}
                </Grid>
                {/* <Grid item xs={12} lg={7} md={12} sm={12} style={{ backgroundColor: '#60C5A3' }}>
                    <p style={{ textAlign: "justify", fontSize: "1em", padding: "5px 20px" }}>
                        <span style={{ fontWeight: "bolder" }}>IMPORTANT:</span> Welcome  to mation , resources ,services , products  and tools we provide ,
                        you understand and adhere to the folowing terms & conditions as stated to the following terms and
                        condition as  stated in this policy .(here after  referred to as user aggrement
                    </p>
                </Grid> */}
            </>
        )
    }
}

export default withRouter(ReturnRefundPolicy);
// Customizable Area End