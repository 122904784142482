Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

////////////Email Login//////////////
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// get otp
exports.getOtpAPIEndPoint = "bx_block_login/logins/create_otp_login";
exports.getOtpAPIMethod = "POST";
exports.getOtpAPIContentType = "application/json";

// verify email otp
exports.verifyEmailOtpAPIEndPoint = "bx_block_login/logins/verify_otp_login";
exports.verifyLoginOtpAPIMethod = "POST";
exports.verifyLoginOtpAPIContentType = "application/json";

// verify mobile otp
exports.verifyMobileOtpAPIEndPoint = "bx_block_login/logins/verify_sms_login";

// POST Profile Data  
exports.profileDataPostContentType = "application/json";
exports.profileDataPostEndPoint = "account_block/accounts/upload_profile_photo";
exports.profileDataAPIMethod = "PUT";

// GET user details
exports.userDetailsContentType = "application/json";
exports.userDetailsGetEndPoint = "account_block/accounts/get_user_profile";
exports.httpGetMethod = "GET";

////////////Google Login//////////////
exports.googleLoginAPiEndPoint = "auth/google_oauth2/callback";
exports.googleLoginAPiMethod = "POST";
exports.googleLoginApiContentType = "application/json";

exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

// for web email and mobile signin
exports.headerSignIn = "Sign In To Your Whitebook Account"
exports.labelSignIn = 'Record the life of your family.'
exports.labelOtpInfo = 'Please check Spam folder If you are not getting OTP in inbox'
exports.errorEmailInvalid = 'Please Enter A Valid Email!'
exports.errorMobileInvalid = 'Please Enter A Valid Phone Number!'
exports.labelCreateAccount = 'Create An Account'
exports.placeHolderPhoneNumber = "XXXXXXXXXX"
exports.placeHolderEmailWeb = "Enter An Email"
exports.placeHolderOTP = "Enter An OTP"
exports.buttonGetOtp = 'Get an OTP'
exports.buttonSignIn = 'Sign In'
exports.pathMyOrders = "/MyOrders"
exports.pathFlipBook = "/FlipBook"
exports.pathCustomiseCoverPage = "/CustomiseCoverPage"
exports.pathHomePage = "/"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

// Customizable Area End