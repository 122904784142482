
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import ReactGA from "react-ga";
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends RouteComponentProps<any> {

  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  history: any;
  location: any;
  closeVisible: boolean;
  RouteComponentProps: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  privacyPolicy: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  privacyPoliciesApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      privacyPolicy: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    // Customizable Area Start

    GoogleAnalytics()
    this.privacyPoliciesRequest()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.privacyPoliciesApiCallId != null &&
      this.privacyPoliciesApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        console.log("Privacy Policy Response==>", responseJson?.data)
        this.setState({privacyPolicy:responseJson?.data?.attributes?.content})

      } else {
        var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  privacyPoliciesRequest = () => {
    console.log('privacyPoliciesApi start')

    const header = {
      "Content-Type": configJSON.privacyPoliciesApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPoliciesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPoliciesAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.privacyPoliciesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("privacyPoliciesApi end")
  };
  // Customizable Area End
}
