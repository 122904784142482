Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfAnimationsAndTransition2";
exports.labelBodyText = "CfAnimationsAndTransition2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.thresholdResolution = 300
exports.totalImageSize = 20

exports.imageAPiEndPoint = `bx_block_book/books`;
exports.imageApiContentType = "multipart/form-data";
exports.apiMethodTypeGet = "GET";

exports.apiUpdateEndPoint = `bx_block_book/books`;
exports.apiUpdateContentType = "application/json"
exports.apiUpdateMethodType = "PUT";

exports.apiUpdateImageEndPoint = "bx_block_attachment/attachments";
exports.apiUpdateImageContentType = "application/json";
exports.apiUpdateImageMethodType = "PUT";

exports.apiDeleteImageEndPoint = "bx_block_attachment/attachments";
exports.apiDeleteImageContentType = "application/json";
exports.apiDeleteImageMethodType = "DELETE";

exports.apiReplaceImageEndPoint = "bx_block_book/books/crop_photo";
exports.apiReplaceImageContentType = "application/json";
exports.apiReplaceImageMethodType = "POST";

exports.apiShuffleImageEndPoint = "bx_block_book/books/shuffle_photos";
exports.apiShuffleImageContentType = "application/json";
exports.apiShuffleImageMethodType = "POST";

exports.apiCropImageEndPoint = "bx_block_book/books/crop_photo";
exports.apiCropImageContentType = "application/json";
exports.apiCropImageMethodType = "POST";

exports.apiPreSignedEndPoint = 'bx_block_book/presigned_uploads';
exports.apiPreSignedMethod = 'POST';
exports.apiPreSignedContentType = "application/json";

exports.apiBookUpdateEndPoint = 'bx_block_book/books/book_update_new'
exports.apiBookUpdateMethod = 'PUT'
exports.apiBookUpdateContentType = "application/json"

exports.apiGetImageLimitEndPoint = "bx_block_book/books/get_image_limit"
exports.apiGetImageLimitMethodType = 'GET'
exports.apiGetImageLimitContentType = "application/json";

exports.devFrontURL = "https://snapslikeapp2-89023-react-native.b89023.dev.eastus.az.svc.builder.cafe"
exports.stageFrontURL = "https://snapslikeapp2-89023-react-native.b89023.stage.eastus.az.svc.builder.ai"

exports.devMinioEndPoint = "minio.b89023.dev.eastus.az.svc.builder.cafe"
exports.stageMinioEndPoint = "minio.b89023.stage.eastus.az.svc.builder.ai"
exports.prodMinioEndPoint = "minio.b89023.prod.centralindia.az.svc.builder.ai"
exports.minioAccessKey = "hello"
exports.minioSecretKey = "builderai"
exports.bucketName = "sbucket"

exports.titleType = ["bottom", "top"];
exports.pageType = ["mainPage", "colorEdit", "newCoverEdit","titleChange"];

exports.getbookColorEndpoint = "bx_block_book/books/book_color";
exports.getbookColorContentType = "application/json";
exports.getbookColorMethodType = "GET";
// Customizable Area End