Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";

exports.storiesApiContentType = "application/json";
exports.storiesApiMethodType = "GET";
exports.storiesApiEndPoint = "bx_block_stories/stories/active_stories";

exports.blogsApiContentType = "application/json";
exports.blogsApiMethodType = "GET";
exports.blogsApiEndPoint = "bx_block_stories/blogs/active_blogs";

exports.singleblogApiContentType = "application/json";
exports.singleblogApiMethodType = "GET";
exports.singleblogApiEndPoint = "/bx_block_stories/blogs";

// Customizable Area End