export const logoImg = require("../assets/logo black.png");
// export const landingMobImg = require("../assets/landing-page-image1.jpg");
export const landingMobImg = require("../assets/landing-page-image.webp");
export const reviewImg = require("../assets/reviews.jpg");
export const star = require('../assets/star.png');
export const bookImg = require('../assets/Book-image.png');
export const qualityMark = require('../assets/Quality-mark.png');
export const yellowStar= require('../assets/star gif.png');
export const aboutUs = require('../assets/about us.png');
export const rightArrow = require('../assets/right-arrow.png');
export const menu =require('../assets/menu.png');
export const footerImg = require("../assets/bottom image.jpg");
export const footerLogo = require("../assets/whitebook_logo_blue.svg");
export const twitterImg= require('../assets/twitter.png');
export const instImg = require('../assets/instagram.png');
export const bottomNav = require('../assets/bottom navbar1.png');
export const fbImg=require('../assets/facebook.png');

export const memories1 = require("../assets/memories-1.png");
export const memories2 = require("../assets/memories-2.png");
export const memories3 = require("../assets/memories-3.png");
export const memories4 = require("../assets/memories-4.png");
export const BookVectorTop = require("../assets/book-vector-top.png");
export const FooterBottom = require("../assets/footer-bottom.png");
export const MobileScreens = require("../assets/mobile-screens.png");

export const TestimonialGallery1 = require("../assets/testimonial_1.png");
export const TestimonialGallery2 = require("../assets/testimonial_2.png");
export const TestimonialGallery3 = require("../assets/testimonial_3.png");
export const TestimonialGallery4 = require("../assets/testimonial_4.png");
export const TestimonialGallery5 = require("../assets/testimonial_5.png");
export const TestimonialGallery6 = require("../assets/testimonial_6.png");
export const TestimonialGallery7 = require("../assets/testimonial_7.png");
export const TestimonialGallery8 = require("../assets/testimonial_8.png");
export const TestimonialGallery9 = require("../assets/testimonial_9.png");
export const TestimonialGallery10 = require("../assets/testimonial_10.png");
export const TestimonialGallery11 = require("../assets/testimonial_11.png");
export const TestimonialGallery12 = require("../assets/testimonial_12.png");

export const StoryIcon = require("../assets/story-icon.png");
// export const SeeTheMagicChaos = require("../assets/see-the-magic.png");
export const SeeTheMagicChaos = require("../assets/see-the-magic.png");
export const LandingPageVideo = require("../assets/landing-page-video.mp4");
export const LandingBelowVideo = require("../assets/below-video-img.svg");
export const SeeTheMagicEveryday = require("../assets/see-the-magic-everyday.png");

export const CarouselText = require("../assets/replacement_image_for_customise_your_book_text.svg");
export const Carousel1 = require("../assets/32__1_.png");
export const Carousel2 = require("../assets/33.png");
export const Carousel3 = require("../assets/34.png");
export const Carousel4 = require("../assets/35.png");