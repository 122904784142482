import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { RouterProps } from "react-router";
const config = require('../../../framework/src/config')
export const configJSON = require("./config");
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web"
// Customizable Area End

export type Props = RouterProps & {
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader: any;
  hideLoader: any;
  navigation: any;
  history: any;
  location: any,
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string,
  mobileNumber: string,
  loginOtp: string,
  loginOtpSent: boolean,
  pin: number,
  isLoginOTPVerified: boolean,
  showLogin: boolean,
  showLogout: boolean,
  name: string,
  emailId: string,
  otpTimer: number,
  token: string,
  open: boolean,
  errorOpen: boolean,
  vertical: any,
  horizontal: any,
  successMessage: any,
  errorMessage: any,
  isValidEmail: boolean,
  isValidMobile: boolean,
  invalidEmailMessage: string,
  invalidMobileMessage: string,
  path: any,
  isPopupOpen: boolean,
  isButtonDisable: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currentCountryCode: any;
  loginAccountAPICallId: string = "";
  googleLoginAccountAPICallId: string = "";
  getOtpAPICallId: string = "";
  verifyLoginOtpAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
        email: '',
        mobileNumber: '',
        loginOtp: '',
        loginOtpSent: false,
        pin: 0,
        otpTimer: 90,
        isLoginOTPVerified: false,
        showLogin: true,
        showLogout: false,
        name: "",
        emailId: "",
        token: "",
        open: false,
        errorOpen: false,
        vertical: 'top',
        horizontal: 'center',
        successMessage: null,
        errorMessage: null,
        isValidEmail: false,
        isValidMobile: false,
        invalidEmailMessage: "",
        invalidMobileMessage: "",
        path: null,
        isPopupOpen: false,
        isButtonDisable: false     
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    GoogleAnalytics()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    console.log("receive")
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // get otp api response  
      this.getOtpReceive(apiRequestCallId,responseJson)
      
      ////////////Google Login///////////////
      if (apiRequestCallId === this.googleLoginAccountAPICallId) {
        if (responseJson && responseJson.meta) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          await StorageProvider.set('authToken', JSON.stringify(responseJson?.meta?.token));
          this.props.showToast({ type: 'success', message: "Sign In Successfully" });
          setTimeout(() => window.location.href = configJSON.pathHomePage, 700)
        } else {
          this.props.showToast({ type: 'error', message: "Error, please try again" });
          setTimeout(() => window.location.reload(), 700)
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getOtpReceive = (apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.getOtpAPICallId) {
      if (responseJson && responseJson.meta) {
        console.log("Success Response");
        this.setState({
              open: true,
              loginOtpSent: true,
              token: responseJson?.meta?.token,
              pin: responseJson?.data?.attributes?.pin,
              successMessage: "OTP Sent Successfully",
              isPopupOpen: true,
              isButtonDisable: false,
          },this.handleOtpTimer)
      } else {
        this.setState({
          open: true,
          errorMessage: responseJson?.errors[0]?.account || responseJson?.errors,
          isButtonDisable: false,
        })
      }
    }
  }

  closePopup = () => {
    this.setState({ isPopupOpen: false })
  }

  /////////////////Email Login Via OTP/////////////////////////
  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value.trim();
    this.setState({ email: email });
    const result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (result.test(String(email).toLowerCase())) {
      this.setState({ invalidEmailMessage: "", isValidEmail: true });
    } else {
      this.setState({ invalidEmailMessage: configJSON.errorEmailInvalid, isValidEmail: false });
    }
  }

  handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mobile = e.target.value.trim()
    const pattern =  /^\d{10}$/;
    pattern.test(String(mobile)) ? this.setState({ mobileNumber: mobile, invalidMobileMessage: "", isValidMobile: true }) : this.setState({ mobileNumber: mobile, invalidMobileMessage: configJSON.errorMobileInvalid, isValidMobile: false });
  }

  handleLoginOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ loginOtp : e.target.value,isButtonDisable:false })

  handleOtpTimer = () => {
    const timer = this.state.otpTimer > 0 && setInterval(() => {
      if (this.state.otpTimer === 0) {
        //@ts-ignore
        clearInterval(timer)
      } else {
        this.setState({ otpTimer: this.state.otpTimer - 1 })
      }

    }
      , 1000);
  }
 
  handleClose = () => {
    this.setState({ open: false })
  }


  getOtp = () => {
    this.setState({
      isButtonDisable: !this.state.isButtonDisable
    })
    const header = {
      "Content-Type": configJSON.getOtpAPIContentType,
    };
    const data = {
      attributes: this.state.email ? 
                  { email: this.state.email.toLowerCase() } : 
                  { full_phone_number: '91' + this.state.mobileNumber }
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOtpAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtpAPIEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtpAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyEmailLoginOtp = () => {
    const endpoint = configJSON.verifyEmailOtpAPIEndPoint
    const fullURL = endpoint?.indexOf('://') === -1 ? config?.baseURL + '/' + endpoint : endpoint
    fetch(fullURL,
      {
        method: configJSON.verifyLoginOtpAPIMethod,
        body: JSON.stringify({
          data: {
            type: "email_account",
            attributes: {
              email: this.state.email.toLowerCase(),
              pin: Number(this.state.loginOtp)
            }
          }
        }),
        headers: {
          "Content-type": configJSON.verifyLoginOtpAPIContentType,
          "token": this.state.token
        }
      })
      .then(response => response.json().then(data => ({ ...data, "status": response.status })))
      .then(async (json) => {
        this.setState({ successMessage: null, errorMessage: null })

        if (json.status === 200 && Number(this.state.loginOtp) === Number(this.state.pin)) {
          this.setState({ open: true, successMessage: "Sign In Successfully" })
          await StorageProvider.set('authToken', JSON.stringify(json?.meta?.token));
          await StorageProvider.set('sharable_link', JSON.stringify(json?.meta?.shareble_link));
          this.redirectToPath(this.props?.location?.state?.path, JSON.stringify(json?.meta?.token))
        } else {
          if(json?.errors[0]?.failed_login === "Account not found, or not activated"){
            this.setState({ errorMessage: "Account Not Found Or Not Activated", open: true, isButtonDisable: false })
          }else{
            this.setState({ errorMessage: "Please Enter A Valid OTP", open: true, isButtonDisable: false })
          }
        }
      });
  }

  verifyMobileLoginOtp = () => {
    const endpoint = configJSON.verifyMobileOtpAPIEndPoint
    const fullURL = endpoint?.indexOf('://') === -1 ? config?.baseURL + '/' + endpoint : endpoint
    fetch(fullURL,
      {
        method: configJSON.verifyLoginOtpAPIMethod,
        body: JSON.stringify({
          data: {
            type: "email_account",
            attributes: {
              full_phone_number: '91' + this.state.mobileNumber,
              pin: Number(this.state.loginOtp)
            }
          }
        }),
        headers: {
          "Content-type": configJSON.verifyLoginOtpAPIContentType,
          "token": this.state.token
        }
      })
      .then(response => response.json().then(data => ({ ...data, "status": response.status })))
      .then(async (json) => {
        this.setState({ successMessage: null, errorMessage: null })

        if (json.status === 200 && Number(this.state.loginOtp) === Number(this.state.pin)) {
          this.setState({ open: true, successMessage: "Sign In Successfully" })
          await StorageProvider.set('authToken', JSON.stringify(json?.meta?.token));
          await StorageProvider.set('sharable_link', JSON.stringify(json?.meta?.shareble_link));
          this.redirectToPath(this.props?.location?.state?.path, JSON.stringify(json?.meta?.token))
        } else {
          this.setState({ open: true, isButtonDisable: !this.state.isButtonDisable })
          if(json?.errors[0]?.failed_login === "Account not found, or not activated"){
            this.setState({ errorMessage: "Account Not Found Or Not Activated" })
          }else{
            this.setState({ errorMessage: "Please Enter A Valid OTP" })
          }
        }
      });
  }

  redirectToPath = (path: string, token: string) => {
    const state = { 
        backFlag:true,
        successMessage: this.state.successMessage, 
        bookId: this.props?.location?.state?.bookId, 
        images: this.props?.location?.state?.images, 
        total_pages: this.props?.location?.state?.total_pages, 
        bookTitle: this.props?.location?.state?.bookTitle,
        cover_image: this.props?.location?.state?.cover_image,
        token 
    }
    switch (path) {
        case configJSON.pathMyOrders:
            setTimeout(() => this.props.history.push({ pathname: configJSON.pathMyOrders, state }),1500)
            break;
        case configJSON.pathFlipBook:
            setTimeout(() => this.props.history.push({ pathname: configJSON.pathFlipBook, state }),1500)
            break;
        case configJSON.pathCustomiseCoverPage:
            setTimeout(() => this.props.history.push({ pathname: configJSON.pathCustomiseCoverPage, state }),1500)
            break;
        default:
            setTimeout(() => this.props.history.push({ pathname: configJSON.pathHomePage }),1500)
            break;
    }
  }

  /////////////////Google Login /////////////////////////
  handleOnSuccess = (res: any) => {
    console.log("Login Success:", res);
    // this.setState({name:res.profileObj.})
    console.log(res.accessToken);
    this.doGoogleLogin()
    this.setState({ showLogout: true });
    this.setState({ showLogin: false });
  }
  handleOnFailure = (res: any) => {
    console.log("Login Failed:", res)
  }
  handleOnLogout = () => {
    alert("Logout Successfully");
    this.setState({ showLogout: false });
    this.setState({ showLogin: true });
  }

  doGoogleLogin = () => {
    const header = {
      "Content-Type": configJSON.googleLoginApiContentType,
    };
    const httpBody = {
      access_token: "Hello",
    };
    console.log("sent data : ", httpBody)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.googleLoginAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.googleLoginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("function end")
  }
  // Customizable Area End
}