import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import StorageProvider from '../../../framework/src/StorageProvider';
import googleController, { GoogleWebDelegate } from "./GoogleWebController";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
import { Console } from "console";
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web";
export type Props = RouterProps & {
  id: string;
  showToast: any;
  showLoader: any;
  hideLoader: any;
  navigation: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  open: boolean,
  errorOpen: boolean,
  vertical: any,
  horizontal: any,
  successMessage: any,
  errorMessage: any,
  isValid: boolean,
  validMessage: any,

}

interface SS { }

export default class SocialMediaAccountWebController
  extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  createAccountAPICallId: any;
  googleUser: any;
  googleLoginAccountAPICallId: any;

  constructor(props: Props) {

    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ]);

    this.state = {
      loading: false,
      isRegistration: false,
      open: false,
      errorOpen: false,
      vertical: 'top',
      horizontal: 'center',
      successMessage: null,
      errorMessage: null,
      isValid: false,
      validMessage: null,
    };
  }

  async componentDidMount() {
    GoogleAnalytics()
  }

  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    console.log("userInfo", userInfo)
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.email, userInfo.id, this.props);
    } else {
      this.doGoogleLogin(localStorage.access_token, userInfo.email)
    }

    // if (localStorage.access_token) {
    //   this.doGoogleLogin(localStorage.access_token)
    // } else {
    //   this.logInWithSocial(
    //     userInfo.email,
    //     userInfo.email,
    //     userInfo.id,
    //     this.props
    //   );
    // }
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {
    this.stopLoading();
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email, response.id, this.props);
    } else {
      this.logInWithSocial(
        response.email,
        response.email,
        response.id,
        this.props
      );
    }
    runEngine.debugLog(response);
  };

  googleLogIn = (isRegistration: boolean) => {
    console.log("is", isRegistration)
    const self = this;
    //@ts-ignore
    if (JSON.parse(localStorage.getItem('isCookieEnabled'))) {
      //@ts-ignore
      googleController.googleLogIn(this).then(
        function () {
          // self.doGoogleLogin(localStorage.access_token)
          self.stopLoading();
        },
        function (error: any) {
          self.stopLoading();
          if (error.error === "popup_closed_by_user") {
            //handle window closed event
          }
        }
      );
    } else {
      alert('Please enable cookies')
    }

  };

  async receive(from: string, message: Message) {
    // this.stopLoading();

    // if (getName(MessageEnum.SessionSaveMessage) === message.id) {
    //   this.openInfoPage();
    // } else if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.createAccountAPICallId != null &&
    //   this.createAccountAPICallId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );

    //   if (responseJson && responseJson.meta && responseJson.meta.token) {
    //     console.log(responseJson, "responseJsonresponseJsonresponseJson")
    //     this.saveLoggedInUserData(responseJson);
    //   } else if (responseJson && responseJson.errors) {
    //     this.parseApiErrorResponse(responseJson);
    //   } else {
    //     var errorReponse = message.getData(
    //       getName(MessageEnum.RestAPIResponceErrorMessage)
    //     );
    //     this.parseApiCatchErrorResponse(errorReponse);
    //   }
    // } else {
    //   runEngine.debugLog("GOIT");
    // }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.createAccountAPICallId) {
        if (responseJson && responseJson.meta) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.props.showToast({ type: 'success', message: responseJson.meta.message });
          setTimeout(() => window.location.href = "/"
            , 1000)
        } else {
          this.props.showToast({ type: 'error', message: "Error, please try again" });
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId === this.googleLoginAccountAPICallId) {
        if (responseJson && !responseJson.errors) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          console.log("response", responseJson.message)
          await StorageProvider.set('authToken', JSON.stringify(responseJson?.token));
          this.setState({
            open: true,
            successMessage: "Sign In Sucessfully"
          })

          if (this.props?.location?.state?.path === "/FlipBook") {
            setTimeout(() => this.props.history.push({
              pathname: "/FlipBook",
              state: {backFlag:false, open: true, successMessage: this.state.successMessage, bookId: this.props?.location?.state?.bookId, images: this.props?.location?.state?.images, total_pages: this.props?.location?.state?.total_pages, bookTitle: this.props?.location?.state?.bookTitle }
            }), 1500)
          } else if (this.props?.location?.state?.path === "/CustomiseCoverPage") {
            setTimeout(() => this.props.history.push({
              pathname: "/CustomiseCoverPage",
              state: {backFlag:false, open: true, successMessage: this.state.successMessage, bookId: this.props?.location?.state?.bookId, images: this.props?.location?.state?.images, total_pages: this.props?.location?.state?.total_pages, bookTitle: this.props?.location?.state?.bookTitle }
            }), 1500)
          } else {
            setTimeout(() => this.props.history.push({
              pathname: "/",
              // state: {open:true, successMessage:this.state.successMessage }
            }), 1500)
          }
        } else {
          this.setState({
            open: true,
            errorMessage: responseJson?.errors[0]?.account
          })
          // this.props.showToast({ type: 'error', message: "Error, please try again" });
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }

  async createAccountFromSocial(
    incomingEmail: String,
    incomingId: String,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountURL
    );

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId
      }
    };

    const httpBody = {
      data: data
    };

    console.log("body", httpBody)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props

  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      return;
    }
    console.log("props", props)

    this.startLoading();

    const header = {
      "accept": "application/json"
    };

    let formData = new FormData();
    formData.append('email', incomingEmail);
    console.log("email", incomingEmail)
    formData.append('provider', 'google_oauth2');
    formData.append('type', 'SocialAccount');
    console.log("formData", formData)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAccountURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  doGoogleLogin = (access_token: any, email: string) => {
    const header = {
      "Content-Type": configJSON.googleLoginApiContentType,
    };

    const httpBody = {
      access_token: access_token,
      email: email
    };

    console.log("[google login]sent data : ", httpBody)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.googleLoginAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.googleLoginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("function end")
  }

  navigate() {
    runEngine.debugLog(this.state.isRegistration);
    if (this.state.isRegistration) {
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToSignup();
    } else {
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToLogin();
    }
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
        this.state.isRegistration
          ? MessageEnum.AccoutResgistrationSuccess
          : MessageEnum.AccoutLoginSuccess
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook
  };

  btnGoogleLogInProps = {
    onPress: () => {
      console.log("loacal storage", localStorage)
      console.log("storage Provider", StorageProvider)
      // this.doGoogleLogin(localStorage.access_token)
      this.googleLogIn(this.state.isRegistration);
      this.startLoading();
    }
  };

  btnNavigateProps = {
    onPress: () => this.navigate()
  };
}
