export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const galleryLogo=require("../assets/galleryPhotoLogo.png");
export const googlePhotoLogo=require("../assets/googlePhotoLogo.png");
export const ticked=require("../assets/ticked.png");
export const forwardButton=require("../assets/forwardButton.png");
export const plus = require('../assets/plus.png');
export const unTicked = require("../assets/grayticked.png");
export const landingMobImg = require("../assets/landing page image1.jpg");
export const nextButton = require("../assets/rightArrow.png");
export const prevButton = require("../assets/leftArrow.png");
export const shareImg = require("../assets/shareImg.png");
export const whatsApp = require("../assets/whatsApp.jpg");
export const emailApp = require("../assets/emailApp.jpg");
export const messageApp = require("../assets/messageApp.jpg");
export const twiterApp = require("../assets/twiterApp.jpg");
export const linkedinApp = require("../assets/linkedinApp.jpg");
export const facebookApp = require("../assets/facebookApp.jpg");
export const blackLogo = require("../assets/logo black.png");
export const whiteLogo = require("../assets/logo white.png");
export const threeHumanImage = require("../assets/threeHumanImage.png");
export const shareIcon = require("../assets/shareIcon.png");
export const numberOneImage = require("../assets/numberOneImage.png");
export const numberTwoImage = require("../assets/numberTwoImage.png");
export const backBlueIcon = require("../assets/backBlueIcon.png");
export const contributionLogo = require("../assets/contributionLogo.png");
export const blueLogo = require("../assets/blueLogo.svg");