// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
// import registerServiceWorker from '../../components/src/registerServiceWorker';
import { ToastProvider } from "../../components/src/ToastContext";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import * as serviceWorker from './serviceWorker';



const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#29BDBD',
      contrastText: "#ffffff"
    },
    secondary: {
      main: '#87A1ED',
      contrastText: "#ffffff"
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <ToastProvider>
        <App />
      </ToastProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
// registerServiceWorker();
serviceWorker.unregister();
