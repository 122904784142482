"use strict";

// const baseURL = "https://snapslikeapp2-89023-ruby.b89023.dev.eastus.az.svc.builder.cafe"
const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL;
