//@ts-nocheck
import React from 'react';
// Customizable Area Start
import { Grid, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router";
import AboutUsController from './AboutUsController.web';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import "./CMS.css";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loader from "../../../components/src/Loader.web";


class AboutUs extends AboutUsController {

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 900,
            slidesToShow: 1,
            autoplay: true,

        };

        return (
            <Grid container direction="row" justifyContent="center" style={{ marginTop: "0px", paddingTop: "54px" }}>
                <Grid item xs={12} lg={7} md={12} sm={12} style={{ marginBottom: "0px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", background: "#ffffff", position: "fixed", top: 0, zIndex: 2, padding: "15px 0" }}>
                        <div style={{ fontSize: '1.2em', cursor: 'pointer', position: "absolute", left: "0", marginLeft: "15px" }} onClick={() => { this.props.history.push("/Menu") }} >
                            <ArrowBackIos />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ margin: "0", fontSize: "20px", fontFamily: "inherit" }}>About Us</h3>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} alignItems='center' justifyContent='center' style={{ margin: ' 40px 0px' }}>
                    <Slider {...settings} >
                        {this.state.imgUrls?.map((image: any, i: any) => <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <img height={200} width={"auto"} style={{ border: '3px solid #29BDBD', borderRadius: '20px', margin: 'auto', maxWidth: "100%" }} src={image} alt="" />
                        </div>
                        )}
                    </Slider>
                </Grid>

                <Grid item xs={12} lg={7} md={12} sm={12}>
                    {this.state.description === null ? <Loader loading={true}/> : <div className="ql-editor"><div dangerouslySetInnerHTML={{ __html: this.state.description }} /> </div>}
                </Grid>
            </Grid >
        )
    }
}

export default withRouter(AboutUs);
// Customizable Area End