// Customizable Area Start
import React from 'react';
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import EmailAccountLoginController from './EmailAccountLoginController.web';
import { withRouter } from "react-router";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import withSnackBar from "../../../components/src/withSnackBar.Web";
import Popup from "../../../components/src/Popup.web";
import InputAdornment from '@material-ui/core/InputAdornment';
import "./signin.css"

const configJSON = require("./config");

export class EmailSignIn extends EmailAccountLoginController {
    message = () => {
        if (this.state.successMessage) {
            return `${this.state.successMessage}`
        } else if (this.state.errorMessage) {
            return `${this.state.errorMessage}`
        } else {
            return `${this.state.successMessage}`
        }
    }

    classReturn = () => {
        if (!(this.state.isValidEmail || this.state.isValidMobile)) {
            return "textCapitalize btn-rounded btn-checkout bg-disabled btn-touch-hover"
        } else {
            return "textCapitalize btn-rounded bg-green btn-checkout btn-touch-hover"
        }
    }

    returnSignInButton = () => (
        this.state.isButtonDisable ?
            <Button
                className="textCapitalize btn-rounded btn-checkout bg-disabled"
                size="medium"
                variant="contained"
                disabled={this.state.isButtonDisable}
                color="primary"
            >
                {configJSON.buttonSignIn}
            </Button>
            :
            <Button
                onClick={() => this.setState({ isButtonDisable: !this.state.isButtonDisable }, this.state.email ? this.verifyEmailLoginOtp : this.verifyMobileLoginOtp)}
                id="btnSignIn"
                className={this.state.loginOtp === '' ? "textCapitalize btn-rounded btn-checkout bg-disabled" : "textCapitalize btn-rounded bg-green btn-checkout"}
                size="medium"
                variant="contained"
                disabled={this.state.loginOtp === ''}
                color="primary"
            >
                {configJSON.buttonSignIn}
            </Button>
    )

    returnGetOtpButton = () => (
        this.state.isButtonDisable ?
            <Button
                className="textCapitalize btn-rounded btn-checkout bg-disabled btn-touch-hover"
                size="medium"
                variant="contained"
                color="primary"
                disabled={this.state.isButtonDisable}
            >
                {configJSON.buttonGetOtp}
            </Button>
            :
            <Button
                onClick={this.getOtp}
                className={this.classReturn()}
                size="medium"
                variant="contained"
                color="primary"
                id='btnGetOtp'
                disabled={!(this.state.isValidEmail || this.state.isValidMobile)}
            >
                {configJSON.buttonGetOtp}
            </Button>
    )

    button = () => {
        return (
            <>
                <Grid item md={12} xs={12} style={{ textAlign: 'center' }} >
                    {
                        this.state.pin ?
                            this.returnSignInButton()
                            :
                            this.returnGetOtpButton()
                    }
                </Grid>
            </>
        )
    }

    render() {

        const { open, isValidEmail, isValidMobile, invalidEmailMessage, invalidMobileMessage, otpTimer } = this.state;
        return (
            <>
                <Grid container direction="row" justifyContent="center">

                    <Popup
                        style={{ top: "3%", left: "0", right: "0", width: this.state.successMessage ? "80%" : "90%", maxWidth: "260px", bottom: "auto", margin: "auto", transform: "none" }}
                        flag={open}
                        handleClose={() => this.setState({ open: false })}
                        massage={this.message()}
                    />
                    <span
                        style={{ cursor: 'pointer', position: "absolute", top: "20px", left: "20px" }}
                        onClick={() => this.props.location?.state?.path == '/FlipBook' ?
                            this.props.history.push({ pathname: '/Flipbook', state: this.props?.location?.state }) :
                            this.props.history.push('/')
                        }><ArrowBackIos /></span>
                    <Grid item md={12} style={{ textAlign: 'center', marginTop: "40px" }}>
                        <h2>{configJSON.headerSignIn}</h2>
                    </Grid>
                    <p style={{ fontSize: '20px', margin: "0px 30px 0px 30px", textAlign: "center" }}>{configJSON.labelSignIn}</p>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                        <br />
                        <b>Email</b>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <TextField
                            style={{ textTransform: "lowercase", marginTop: '8px' }}
                            margin="normal"
                            placeholder={configJSON.placeHolderEmailWeb}
                            variant="outlined"
                            fullWidth
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            inputProps={{ borderRadius: "50px" }}
                            disabled={Boolean(this.state.mobileNumber)}
                        />
                        <div className={`message ${!isValidEmail ? 'error' : 'success'}`}>
                            {this.state.email === "" ? "" : invalidEmailMessage}
                        </div>
                    </Grid>
                    <Grid item md={12} xs={12} style={{}}>
                        <p style={{ marginBlock: "5px", textAlign: "center", fontWeight: "bold" }}>OR</p>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                        <b>Phone Number</b>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: "10px" }}>
                        <TextField
                            style={{ marginTop: '8px' }}
                            margin="normal"
                            placeholder={configJSON.placeHolderPhoneNumber}
                            variant="outlined"
                            fullWidth
                            value={this.state.mobileNumber}
                            onChange={this.handleMobileNumberChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <span style={{ color: "#000" }}>+91</span>
                                </InputAdornment>,
                                inputProps: { borderRadius: "50px", maxLength: 10 },
                            }}
                            disabled={Boolean(this.state.email)}
                        />
                        <div
                            className={`message ${!isValidMobile ? 'error' : 'success'}`}
                        >
                            {this.state.mobileNumber === "" ? "" : invalidMobileMessage}
                        </div>
                    </Grid>
                    {
                        this.state.pin ?
                            <>
                                <Grid item md={12} xs={12} style={{ marginLeft: '20px' }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <b>OTP</b>
                                        <div style={{ marginRight: '20px', fontSize: "12px", textAlign: 'right' }}>
                                            <Button onClick={() => this.setState({ loginOtpSent: true, otpTimer: 90 }, this.getOtp)} disabled={otpTimer > 0} style={{ color: otpTimer === 0 ? 'blue' : 'gray', textTransform: "capitalize", padding: '0' }}>Resend OTP</Button>
                                            <br />
                                            {otpTimer > 0 &&
                                                `in ${new Date(otpTimer * 1000).toISOString().substring(14, 19)}s`
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid container md={12} xs={12} style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '10px' }}>
                                    <TextField
                                        style={{ textTransform: "lowercase" }}
                                        margin="normal"
                                        placeholder={configJSON.placeHolderOTP}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.loginOtp}
                                        onChange={this.handleLoginOtpChange}
                                        inputProps={{ borderRadius: "50px" }}
                                    />
                                    <Typography variant='body2' style={{ fontSize: '11px' }}>{configJSON.labelOtpInfo}</Typography>
                                </Grid>
                            </> : null
                    }
                    {this.button()}
                    <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
                        <p style={{ marginTop: "30px" }}><b>OR</b></p>
                    </Grid>
                    <Grid item md={12} style={{ textAlign: 'center', margin: "0" }}>
                        <p style={{ marginTop: "15px" }}><b>Don't have an account?
                            &nbsp;<span style={{ textDecoration: 'underLine', cursor: 'pointer', margin: "0" }}
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: 'EmailSignUp',
                                        state: this.props?.location?.state
                                    })
                                }}
                            >
                                {configJSON.labelCreateAccount}
                            </span>
                        </b></p>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const EmailAccountSignInUpToast = withSnackBar(EmailSignIn);
export default withRouter(EmailAccountSignInUpToast);
// Customizable Area End