// Customizable Area Start
//@ts-nocheck
import React,{Suspense,lazy } from "react";
import LandingPageController from "./LandingPageController.web";

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
// import Hammer from 'react-hammerjs';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import { memories1, memories2, memories3, memories4, BookVectorTop, MobileScreens, qualityMark, TestimonialGallery1, TestimonialGallery2, TestimonialGallery3, TestimonialGallery4, TestimonialGallery5, TestimonialGallery6, TestimonialGallery7, TestimonialGallery8, TestimonialGallery9, TestimonialGallery10, TestimonialGallery11, TestimonialGallery12, StoryIcon, bookImg, landingMobImg, SeeTheMagicChaos, LandingPageVideo, Carousel1, Carousel2, Carousel3, Carousel4, CarouselText, LandingBelowVideo, SeeTheMagicEveryday } from "./assets";

import { StoryIcon, landingMobImg } from "./assets"
// import Footer from './Footer.web';
const Footer = lazy(()=>import("./Footer.web"))

import { withRouter } from "react-router";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { Box, Grid, Typography, Button, Avatar, CircularProgress } from "@material-ui/core";
//import StarRateIcon from '@material-ui/icons/StarRate';
import ShareIcon from '@material-ui/icons/Share';
import NotesIcon from '@material-ui/icons/Notes';
//import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton';
import Snackbar from "@material-ui/core/Snackbar";
//import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web";
const configJSON = require("./config");


class LandingPageNew extends LandingPageController {

  render() {


    const { vertical, horizontal, open} = this.state;

    return (

      // !this.state.adminImages ? <Loader loading={true}/> :
        <React.Fragment>
          {/* <Snackbar open={open}
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={8000}
            style={{ top: "3%", left: "20%", width: "60%" }}
            onClose={this.handleClose}
          >
            <SnackbarContent style={{ display: "flex", justifyContent: "center", padding: "1px 15px", backgroundColor: '#8da4ee', borderRadius: "10px", color: "#fff" }}
              message={
                <div style={{ padding: "0", display: "flex", alignItems: "center", textAlign: "center" }}>
                  <span>{this.props?.location?.state?.successMessage}</span>
                  <IconButton size="small" aria-label="close" color="inherit" style={{ position: "absolute", right: "0", marginRight: "5px" }} >
                    <CloseIcon fontSize="small" onClick={this.handleClose} />
                  </IconButton>
                </div>
              }
            />
          </Snackbar> */}
    
          <Box component="div" m={2}>

            {/* <Grid container spacing={4} style={{ marginBottom: "0" }}>
            <Grid item xs={3}>
              <div style={{...styles.profile, alignItems: "baseline"}}>
                <Avatar className="txt-blue menuItem"><PersonOutlineIcon/></Avatar>
                <Typography variant="caption" align="center">Profile</Typography>
              </div>
            </Grid>
            
            <Grid item xs={9} style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{...styles.profile, marginRight: "10px"}}>
                <Avatar className="txt-blue menuItem" src={StoryIcon} style={{ boxShadow: "0px 0px 0px 2px" }}>AP</Avatar>
                <Typography variant="caption" align="center">Story</Typography>
              </div>

              <div style={{...styles.profile, marginRight: "10px"}}>
                <Avatar className="txt-blue menuItem">
                  <ShareIcon />
                </Avatar>
                <Typography variant="caption" align="center">Refer &amp; Win</Typography>
              </div>

              <div style={styles.profile} onClick={() => { this.props.history.push("Menu") }}>
                <Avatar className="txt-blue menuItem">
                  <NotesIcon />
                </Avatar>
                <Typography variant="caption" align="center">Control</Typography>
              </div>          
            </Grid>
          </Grid> */}

            <Grid container direction="row" spacing={4}>
              {/* <Grid item xs={12}>
              <Typography
                style={{
                  fontWeight: "600",
                  lineHeight: "1.4",
                  fontSize: "clamp(26px, 6vw, 30px)",
                }}
                align="center"
                variant="h4"
                component="h1"
                gutterBottom
              >
                Premium <br />
                Photobook Series by{" "}
                <span style={{ display: "block" }} className="txt-blue">
                  Whitebook
                </span>
              </Typography>
            </Grid> */}

              {/* Mobile Screen */}
              <Grid item xs={12} style={{ position: "relative", paddingBottom: "2em", textAlign: "center", paddingLeft: "0", paddingRight: "0", paddingTop: "0" }}>
                <div style={{ position: "fixed", zIndex: 9, top: 10, right: "0", paddingRight: 10, width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <div className="stories_outer" style={{ marginRight: "10px" }} onClick={() => { this.props.history.push("stories") }}>
                    <Avatar className="txt-blue menuItem" src={StoryIcon}>
                    </Avatar>
                  </div>
                  {this.state.authToken &&
                    <>
                      <div style={{ marginRight: "10px" }} onClick={() => { this.props.history.push("Refer") }}>
                        <Avatar className="txt-blue menuItem">
                          <ShareIcon />
                        </Avatar>
                      </div>
                    </>
                  }
                  <div onClick={() => { this.props.history.push("Menu") }}>
                    <Avatar className="txt-blue menuItem">
                      <NotesIcon />
                    </Avatar>
                  </div>
                </div>
                <img src={landingMobImg} loading="lazy"  style={{ width: "100%" }}/>
                {/* <img src={this.state.adminImages[0]?.attributes?.media_file}  alt="Landing Page" width="100%" /> */}
              </Grid>

              {/* Let's Create */}
              <Grid item xs={12} style={{ padding: "0px 0px 2em", textAlign: "center" }}>
                <Button
                  className="btn-round btn-touch-hover"
                  variant="contained"
                  color="primary"
                  onClick={() => { this.props.history.push("SelectOption") }}
                  style={{ width: "100%", maxWidth: "320px", boxShadow: "0px 2px 3px 5px rgba(135, 161, 237, 0.2)" }}
                >
                  {configJSON.CTAButton}
                </Button>
                {/* {this.state.authToken?
                <Button
                className="btn-round btn-touch-hover"
                variant="contained"
                color="primary"
                onClick={() => { this.props.history.push("SelectOption") }}
                style={{ width: "100%", maxWidth: "320px", boxShadow: "0px 2px 3px 5px rgba(135, 161, 237, 0.2)" }}
              >
                Let's Create
              </Button>
                :
                <Button
                  className="btn-round btn-touch-hover"
                  variant="contained"
                  color="primary"
                  onClick={() => { this.props.history.push("SelectOption") }}
                  style={{ width: "100%", maxWidth: "320px", boxShadow: "0px 2px 3px 5px rgba(135, 161, 237, 0.2)" }}
                >
                  Let's Create
                </Button>
                } */}
              </Grid>

              {/* Memories that last forever */}
              {/* <Grid item xs={12} style={{ paddingTop: "1.5em" }}>
              
              <Grid container alignItems="center" spacing={0} >
                <Grid item xs={5}>
                  <Typography variant="h6" component="h2" style={{ fontWeight: "600", lineHeight: "1.3", marginRight: "15px" }}>
                    Memories that last forever
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={1} >
                    <Grid item xs={7} style={{ paddingBottom: "0" }}>
                      <img src={memories1} style={{...styles.memoriesImg, objectPosition: "left center"}} className="img-fluid" />
                    </Grid>
                    <Grid item xs={5} style={{ paddingBottom: "0" }}>
                      <img src={memories2} style={styles.memoriesImg} className="img-fluid" />
                    </Grid>
                    <Grid item xs={5} style={{ paddingTop: "0" }}>
                      <img src={memories3} style={styles.memoriesImg} className="img-fluid" />
                    </Grid>
                    <Grid item xs={7} style={{ paddingTop: "0" }}>
                      <img src={memories4} style={styles.memoriesImg} className="img-fluid" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid> */}

              {/* <Grid item xs={12} style={{ paddingLeft: "0", paddingRight: "0", paddingBottom: "0" }}>
                <div>
                  <video ref="vidRef" controls={true} autoPlay={true} muted={true} width="100%" style={{ marginBottom: "-7px" }}>
                    <source src={this.state.adminImages[1]?.attributes?.media_file} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Grid> */}

              {/* <Grid item xs={12} style={{ padding: "0" }}>
                <img src={this.state.adminImages[2]?.attributes?.media_file} loading="lazy" style={{ width: "100%", objectFit: "cover", maxWidth: "100%", marginBottom: "-8px" }} />
              </Grid> */}

              {/* <Grid item xs={12} style={{ paddingTop: "2em", paddingBottom: "2em", color: "#ffffff", ...styles.stateSection }} className="familyStats">
                <Grid container spacing={2} >
                  <Grid item xs={7} >
                    <h4 id="familyLoves" style={{ fontSize: "35px", fontWeight: "600", lineHeight: "1.8", margin: "0", textAlign: "center" }}></h4>
                    <Typography align="center" variant="h4" style={{ fontSize: "clamp(20px, 5vw, 28px)", fontWeight: "400" }}>Families Love us</Typography>
                  </Grid>

                  <Grid item xs={5} >
                    <h4 id="familyRates" style={{ fontSize: "35px", fontWeight: "600", lineHeight: "1.8", margin: "0", textAlign: "center" }}></h4>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                    </div>
                  </Grid>
                </Grid>
              </Grid> */}

              {/* below grid should be commented */}
              {/* <Grid item xs={12} style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", borderBottom: "3px solid" }} className="txt-green">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="hoverTestimonials">
                    <div className="frontContainer">
                      <img src={TestimonialGallery1} />
                    </div>
                    <div className="hoverContainer">
                      img 1
                    </div>
                    <div className="overlay" onClick={this.flipBox}></div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="hoverTestimonials">
                    <div className="frontContainer">
                      <img src={TestimonialGallery2} />
                    </div>
                    <div className="hoverContainer">
                      img 2
                    </div>
                    <div className="overlay" onClick={this.flipBox}></div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="hoverTestimonials">
                    <div className="frontContainer">
                      <img src={TestimonialGallery3} />
                    </div>
                    <div className="hoverContainer">
                      img 3
                    </div>
                    <div className="overlay" onClick={this.flipBox}></div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="hoverTestimonials">
                    <div className="frontContainer">
                      <img src={TestimonialGallery4} />
                    </div>
                    <div className="hoverContainer">
                      img 4
                    </div>
                    <div className="overlay" onClick={this.flipBox}></div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="hoverTestimonials">
                    <div className="frontContainer">
                      <img src={TestimonialGallery5} />
                    </div>
                    <div className="hoverContainer">
                      img 5
                    </div>
                    <div className="overlay" onClick={this.flipBox}></div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="hoverTestimonials">
                    <div className="frontContainer">
                      <img src={TestimonialGallery6} />
                    </div>
                    <div className="hoverContainer">
                      img 6
                    </div>
                    <div className="overlay" onClick={this.flipBox}></div>
                  </div>
                </Grid>
              </Grid>
            </Grid> */}

              {/* <Grid item xs={12} style={{ paddingLeft: "10px", paddingRight: "10px", borderBottom: "3px solid" }} className="txt-green">
                <div className="bookCarousel"
                  onTouchStartCapture={() => {
                    this.setState({ playCarousel: false })
                    console.log("Touch Start")
                  }} onTouchEndCapture={() => {
                    this.setState({ playCarousel: true })
                    console.log("Touch End")
                  }}
                >
                  <Carousel
                    showArrows={true}
                    centerMode={false}
                    axis='horizontal'
                    showStatus={false}
                    showThumbs={false}
                    width="100%"
                    autoPlay={this.state.playCarousel}
                    infiniteLoop={true}
                    showIndicators={false}
                    stopOnHover={!this.state.playCarousel}
                  >
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[14]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[13]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[3]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[4]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[6]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[5]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[7]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[8]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[12]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[9]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[10]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                        <Grid item xs={6}>
                          <img src={this.state.adminImages[11]?.attributes?.media_file} loading="lazy" />
                        </Grid>
                      </Grid>
                    </div>
                  </Carousel>
                </div>
              </Grid> */}

              {/* Get Started */}
              {/* <Grid item xs={12} style={{ paddingTop: "1.2em", textAlign: "center" }}>
                <Button
                  className="btn-round btn-touch-hover"
                  variant="contained"
                  color="primary"
                  onClick={() => { this.props.history.push("SelectOption") }}
                  style={{ width: "100%", maxWidth: "320px", boxShadow: "0px 2px 3px 5px rgba(135, 161, 237, 0.2)" }}
                >
                    {configJSON.CTAButton}
                </Button>
              </Grid> */}

              {/* <Grid item xs={12}>
                <img src={this.state.adminImages[15]?.attributes?.media_file} width="100%" loading="lazy" />
              </Grid> */}

              {/* <Grid item xs={12} style={{ paddingLeft: "0", paddingRight: "0" }}>
                <div className="bookCarousel" onTouchStartCapture={() => {
                  this.setState({ playCarousel: false })
                  console.log("Touch Start")
                }} onTouchEndCapture={() => {
                  this.setState({ playCarousel: true })
                  console.log("Touch End")
                }}>
                  <Carousel
                    showArrows={false}
                    centerMode={false}
                    axis='horizontal'
                    showStatus={false}
                    showThumbs={false}
                    width="100%"
                    autoPlay={this.state.playCarousel}
                    infiniteLoop={true}
                    showIndicators={true}
                    stopOnHover={!this.state.playCarousel}
                    interval={5000}
                    emulateTouch={true}
                    swipeable={true}
                  >
                    <div>
                      <img src={this.state.adminImages[16]?.attributes?.media_file} loading="lazy" />
                    </div>
                    <div>
                      <img src={this.state.adminImages[17]?.attributes?.media_file} loading="lazy" />
                    </div>
                    <div>
                      <img src={this.state.adminImages[18]?.attributes?.media_file} loading="lazy" />
                    </div>
                    <div>
                      <img src={this.state.adminImages[19]?.attributes?.media_file} loading="lazy" />
                    </div>
                  </Carousel>
                </div>
              </Grid> */}

              {/* <Grid item xs={12}>

              <ul style={styles.buttonList}>
                <li style={{...styles.buttonListItem, backgroundColor: "#cccccc"}}>Monthbooks</li>
                <li style={{...styles.buttonListItem, backgroundColor: "#B7B7B7"}}>Instabooks</li>
                <li style={{...styles.buttonListItem, backgroundColor: "#70CDCD"}}>Yearbooks</li>
                <li style={{...styles.buttonListItem, backgroundColor: "#24B2B2"}}>Specialbook</li>
                <li style={{...styles.buttonListItem, backgroundColor: "#84B2EE"}}>Travelbook</li>
                <li style={{...styles.buttonListItem, backgroundColor: "#87A1ED"}}>Buy Accessories</li>
              </ul>

            </Grid> */}

              {/* <Grid item xs={12} style={{
                padding: "0", marginBottom: "0px", marginTop: "20px"
              }}>
                <img src={this.state.adminImages[20]?.attributes?.media_file} loading="lazy" className="responsive" />
              </Grid> */}

              {/* <Grid item xs={12} style={{ marginBottom: "20px" }}>
                <Typography variant="h6" gutterBottom align="center" style={{ fontWeight: "600", fontSize: "24px" }}>About Us</Typography>

                <Typography variant="h5" gutterBottom align="center" style={{ fontWeight: "600", marginBottom: "20px" }}>Our vision is simple: making homes happier, bonding families better and celebrating life to the fullest!!</Typography>

                <Typography style={{ fontSize: "20px" }} variant="body1" gutterBottom align="center">We believe that everyday fun, quirky and unexpected moments should be captured, preserved and celebrated together.</Typography>

                <div style={{ textAlign: "center", marginTop: "2em" }}>
                  <Button className="btn-touch-hover" style={{ display: "inline-block", padding: "12px 25px", background: "#ffe59d", borderRadius: "50px", color: "#000000", textTransform: "capitalize" }} onClick={() => { this.props.history.push("AboutUS") }}>Get to know us better</Button>
                </div>
              </Grid> */}

              {/* <Grid item xs={12} style={{ paddingBottom: "0" }}>
                <img src={this.state.adminImages[21]?.attributes?.media_file} loading="lazy" className="responsive" />
              </Grid> */}

              {/* <Grid item xs={12} style={{ paddingBottom: "2em" }}>
                <p style={{ fontSize: '18px', lineHeight: "1.5", textAlign: 'center', padding: "0", margin: "0", fontFamily: "'Montserrat', sans-serif", textTransform: "uppercase" }}>If you are not satisfied with your whitebook<br /> we will reprint or refund you.</p>
              </Grid> */}

              {/* <Grid item xs={12}>
                <div className="bookCarousel testimonialCarousel" onTouchStartCapture={() => {
                  this.setState({ playCarousel: false })
                  console.log("Touch Start")
                }} onTouchEndCapture={() => {
                  this.setState({ playCarousel: true })
                  console.log("Touch End")
                }}>
                  <Carousel
                    showArrows={false}
                    centerMode={false}
                    axis='horizontal'
                    showStatus={false}
                    emulateTouch={true}
                    showThumbs={true}
                    width="100%"
                    autoPlay={this.state.playCarousel}
                    infiniteLoop={true}
                    stopOnHover={!this.state.playCarousel}
                  >
                    <div className="testimonial-item">
                      <Typography variant="subtitle1" style={{ fontSize: "clamp(17px, 5vw, 20px)" }}>"Coolest product ever!!"</Typography>
                      <Typography variant="subtitle2" align="left" style={{ marginLeft: "20px", fontSize: "clamp(16px, 5vw, 17px)", fontWeight: "600" }}>- Aditi Jain</Typography>
                      <ul className="ratings">
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                      </ul>
                    </div>
                    <div className="testimonial-item">
                      <Typography variant="subtitle1" style={{ fontSize: "clamp(17px, 5vw, 20px)" }}>"The Kind Of Product World Needs Right Now !"</Typography>
                      <Typography variant="subtitle2" align="left" style={{ marginLeft: "20px", fontSize: "clamp(16px, 5vw, 17px)", fontWeight: "600" }}>- Monika Sharma</Typography>
                      <ul className="ratings">
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                      </ul>
                    </div>
                    <div className="testimonial-item">
                      <Typography variant="subtitle1" style={{ fontSize: "clamp(17px, 5vw, 20px)" }}>"Simply Magical ! amazing concept"</Typography>
                      <Typography variant="subtitle2" align="left" style={{ marginLeft: "20px", fontSize: "clamp(16px, 5vw, 17px)", fontWeight: "600" }}>- Smriti Khanna</Typography>
                      <ul className="ratings">
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                      </ul>
                    </div>
                    <div className="testimonial-item">
                      <Typography variant="subtitle1" style={{ fontSize: "clamp(17px, 5vw, 20px)" }}>"Brings so much excitement and happy vibes into our lives"</Typography>
                      <Typography variant="subtitle2" align="left" style={{ marginLeft: "20px", fontSize: "clamp(16px, 5vw, 17px)", fontWeight: "600" }}>- Christina J</Typography>
                      <ul className="ratings">
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                        <li><StarRateIcon /></li>
                      </ul>
                    </div>
                  </Carousel>
                </div>
              </Grid> */}
              {/* Get Started */}
              {/* <Grid item xs={12} style={{ paddingTop: "1.2em", textAlign: "center" }}>
                <Button
                  className="btn-round btn-touch-hover"
                  variant="contained"
                  color="primary"
                  onClick={() => { this.props.history.push("SelectOption") }}
                  style={{ width: "100%", maxWidth: "320px", boxShadow: "0px 2px 3px 5px rgba(135, 161, 237, 0.2)" }}
                >
                  {configJSON.CTAButton}
                </Button>
              </Grid> */}

            </Grid>
          </Box>
          <Suspense fallback={<Loader loading={true}/>}>
          <Footer {...this.props} />
          </Suspense>
        </React.Fragment>
    );
  }
}

export default withRouter(LandingPageNew);

const styles = {
  memoriesImg: {
    maxHeight: "calc(60px + 10vw)",
    height: "100%",
    objectFit: "cover" as "cover",
    borderRadius: "8px",
    objectPosition: "center bottom",
  },
  stateSection: {
    background: "linear-gradient(180deg, rgba(41,189,189,1) 0%, rgba(135,161,237,1) 50%)"
  },
  buttonList: {
    listStyle: "none",
    padding: "0",
    margin: "0"
  },
  buttonListItem: {
    padding: "15px 25px",
    textAlign: "center" as "center",
    background: "#dddddd",
    display: "block",
    borderRadius: "30px",
    fontSize: "18px",
    fontWeight: "600",
    marginBottom: "10px"
  },
}

// Customizable Area End