import React from "react";
import { Grid, Button } from "@material-ui/core";
import OrderSummaryController from "./OrderSummaryController.web";
import { withRouter } from "react-router";
import CloseIcon from '@material-ui/icons/Close';

class OrderSummary extends OrderSummaryController {

    render() {

        return (
            <>
                <Grid item md={12} xs={12} style={{ margin: "0px auto", fontFamily: "inherit", marginTop: "30px" }}>
                    <Grid item xs={12}>

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span
                                style={{ fontSize: '1.2em', cursor: 'pointer' }}
                                onClick={() => { this.props.history.push("") }} ><CloseIcon /></span>
                            <h4 style={{ padding: "0px 105px", margin: "0px" }} >3D FlipBook</h4></div>
                        <span style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px", color: "#6705A5" }}>Rotate phone for full screen view</span>
                    </Grid>
                </Grid>
            </>
        )
    }
}
export default withRouter(OrderSummary);