Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login_with_google";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
// exports.clientID =
//   "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.clientID = "135234301433-ijum3idmv9kbs3bpuiramgmb4j204m5i.apps.googleusercontent.com";
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";

exports.headerSignIn = "Sign In To Your Whitebook Account"
exports.labelSignIn = 'Record the life of your family.'

exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Sign In By Google";
exports.loginButtonText = "Log in with email and password";

exports.errorTitle = "Error";
exports.errorDescription = "#Error fetching data: ";

exports.signUpTitleText = "Sign up";
exports.signUpBodyText = "Value proposition: why users should sign up.";

exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";


exports.googleLoginAPiEndPoint = "auth/google_oauth2/callback";
exports.googleLoginAPiMethod = "POST";
exports.googleLoginApiContentType = "application/json";
// Customizable Area End