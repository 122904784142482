Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.aboutUsAPiEndPoint = "about_us";
exports.aboutUsAPiMethod = "Get";
exports.aboutUsApiContentType = "application/json";

exports.privacyPoliciesAPiEndPoint = "privacy_policies";
exports.privacyPoliciesAPiMethod = "Get";
exports.privacyPoliciesApiContentType = "application/json";

exports.termsAndConditionsAPiEndPoint = "terms_and_conditions";
exports.termsAndConditionsAPiMethod = "Get";
exports.termsAndConditionsApiContentType = "application/json";

exports.ReturnRefundPolicyAPiEndPoint = "return_and_refund";
exports.ReturnRefundPolicyAPiMethod = "Get";
exports.ReturnRefundPolicyApiContentType = "application/json";

// Customizable Area End
