Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // Customizable Area Start
exports.localFrontURL = "http://localhost:3000"
exports.devFrontURL = "https://snapslikeapp2-89023-react-native.b89023.dev.eastus.az.svc.builder.cafe";
exports.stageFrontURL = "https://snapslikeapp2-89023-react-native.b89023.stage.eastus.az.svc.builder.ai";
exports.ProdFrontURL = "https://m.whitebook.co.in";

exports.dev_trackingID = "G-DK4EJMX8SJ";
exports.stage_trackingID = "G-YJT6JW1J84";
exports.prod_trackingID = 'G-T5CNG9S59X';
// Customizable Area End