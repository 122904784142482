import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { RouterProps } from "react-router";
import StorageProvider from '../../../framework/src/StorageProvider';
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web";
const config = require('../../../framework/src/config')
export const configJSON = require("./config");
// Customizable Area End

export type Props = RouterProps & {
  id: string;

  // Customizable Area Start
  showToast: any;
  showLoader: any;
  hideLoader: any;
  navigation: any;
  history: any;
  location: any,
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  email: string,
  mobileNumber: string,
  otpEmail: string,
  otpMobile: string,
  emailOtpSent: boolean,
  mobileOtpSent: boolean,
  token: string,
  emailPin: number,
  mobilePin: number,
  open: boolean,
  emailOtpTimer: number,
  mobileOtpTimer: number,
  errorOpen: boolean,
  vertical: any,
  horizontal: any,
  successMessage: any,
  errorMessage: any,
  isValid: boolean,
  invalidMessage: any,
  isEmailOTPVerified: boolean,
  isMobileOTPVerified: boolean,
  isButtonDisabled: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmailOtpAPICallId: string = "";
  getMobileOtpAPICallId: string = "";
  postUniqueReferalAPICallId: string = "";
  putUniqueReferalAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),];

    this.state = {
      email: '',
      mobileNumber: '',
      otpEmail: '',
      otpMobile: '',
      emailOtpSent: false,
      mobileOtpSent: false,
      token: "",
      emailPin: 0,
      mobilePin: 0,
      emailOtpTimer: 90,
      mobileOtpTimer: 90,
      open: false,
      errorOpen: false,
      vertical: 'top',
      horizontal: 'center',
      successMessage: null,
      errorMessage: null,
      isValid: true,
      invalidMessage: null,
      isEmailOTPVerified: false,
      isMobileOTPVerified: false,
      isButtonDisabled: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    GoogleAnalytics()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      ////////////Get Email Otp///////////////     
      if ( apiRequestCallId === this.getEmailOtpAPICallId ) {
        this.getEmailOTPResponse(responseJson)
      }

      // Get Mobile OTP Response
      if ( apiRequestCallId === this.getMobileOtpAPICallId ) {
        this.getMobileOTPResponse(responseJson);  
      }

      // Post Unique Referal Code Response
      if (apiRequestCallId === this.postUniqueReferalAPICallId) {
        if(responseJson) {
          const url = new URL(window.location.href);
          const referred_by = url.searchParams.get('refer');
          if(referred_by){
            this.handleUpdateUniqueReferalCode(referred_by)
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEmailChange = (e: any) => {
    let email = e.target.value.trim()
    this.setState({ email: email })
    // NOSONAR
    const result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    
    if (result.test(String(email).toLowerCase())) {
      this.setState({ invalidMessage: null, isValid: true });
    } else {
      this.setState({ invalidMessage: 'Please Enter A Valid Email!', isValid: false });
    }
  }

  handleMobileNumberChange = (e: any) => {
    const mobile = e.target.value.trim()
    const pattern = /^\d{10}$/;
    pattern.test(String(mobile)) ?
      this.setState({ mobileNumber: mobile, invalidMessage: null, isValid: true }) :
      this.setState({ mobileNumber: mobile, invalidMessage: 'Please Enter A Valid Phone Number', isValid: false });
  }

  handleEmailOtpTimer = () => {

    const timer = this.state.emailOtpTimer > 0 && setInterval(() => {
      if (this.state.emailOtpTimer === 0) {
        //@ts-ignore
        clearInterval(timer)
      } else {
        this.setState({ emailOtpTimer: this.state.emailOtpTimer - 1 })
      }
    }, 1000);
  }

  handleMobileOtpTimer = () => {
    const timer = this.state.mobileOtpTimer > 0 && setInterval(() => {
      if (this.state.mobileOtpTimer === 0) {
        //@ts-ignore
        clearInterval(timer)
      } else {
        this.setState({ mobileOtpTimer: this.state.mobileOtpTimer - 1 })
      }
    }, 1000);
  }

  handleEmailOtpChange = (e: any) => this.setState({ otpEmail: e.target.value })

  handleMobileOtpChange = (e: any) => this.setState({ otpMobile: e.target.value })

  handleClose = () => {
    this.setState({ open: false });
  };

  getEmailOtp = () => {
    this.setState({
      isButtonDisabled: !this.state.isButtonDisabled
    })
    const header = {
      "Content-Type": configJSON.getEmailOtpAPIContentType,
    };
    const data = {
      attributes: {
        email: this.state.email.toLowerCase(),
      }
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmailOtpAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmailOtpAPIEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEmailOtpAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("get otp end")
  }

  getMobileOtp = () => {
    this.setState({
      isButtonDisabled: !this.state.isButtonDisabled
    })
    const header = {
      "Content-Type": configJSON.getMobileOtpAPIContentType,
    };
    const data = {
      attributes: {
        email: this.state.email.toLowerCase(),
        full_phone_number: '91' + this.state.mobileNumber
      }
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMobileOtpAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMobileOtpAPIEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMobileOtpAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("get otp end")
  }

  verifyEmailOtp = () => {
    this.setState({
      isButtonDisabled: !this.state.isButtonDisabled
    })
    const endpoint = configJSON.verifyEmailOtpAPIEndPoint
    const fullURL = endpoint?.indexOf('://') === -1 ? config?.baseURL + '/' + endpoint : endpoint
    const url = new URL(window.location.href);
    const referred_by = url.searchParams.get('refer');
    const bodyData = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email.toLowerCase(),
          pin: Number(this.state.otpEmail),
          referred_by: referred_by,
        }
      }
    }
    fetch(fullURL,
      {
        method: configJSON.verifyEmailOtpAPIMethod,
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": configJSON.verifyEmailOtpAPIContentType,
          "token": this.state.token
        }
      })
      .then(response => response.json())
      .then(async (json) => {
        this.setState({ successMessage: null, errorMessage: null })
        if (Number(this.state.otpEmail) === Number(this.state.emailPin)) {
          this.setState({ isEmailOTPVerified: true,  isButtonDisabled: !this.state.isButtonDisabled })
        } else {
          this.setState({ open: true })
          this.setState({ errorMessage: "Please Enter A Valid OTP", isButtonDisabled: !this.state.isButtonDisabled })
        }
      });
  }

  verifyMobileOtp = () => {
    const endpoint = configJSON.verifyMobileOtpAPIEndPoint
    const fullURL = endpoint?.indexOf('://') === -1 ? config?.baseURL + '/' + endpoint : endpoint
    const bodyData = {
      data: {
        attributes: {
          email: this.state.email.toLowerCase(),
          full_phone_number: '91' + this.state.mobileNumber,
          pin: Number(this.state.otpMobile),
        }
      }
    }
        fetch(fullURL,
        {
            method: configJSON.verifyMobileOtpAPIMethod,
            body: JSON.stringify(bodyData),
            headers: {
            "Content-type": configJSON.verifyMobileOtpAPIContentType,
            "token": this.state.token
            }
        })
        .then(response => response.json())
        .then((json) => {
            this.setState({ successMessage: null, errorMessage: null })
            if (Number(this.state.otpMobile) === Number(this.state.mobilePin)) {
                this.setState({ 
                  open: true, 
                  successMessage: "Sign Up Successfully", 
                  isMobileOTPVerified: true,
                  token: json?.meta?.token
                })
                StorageProvider.set('authToken', JSON.stringify(json?.meta?.token));
                StorageProvider.set('sharable_link', JSON.stringify(json?.meta?.shared_link));
                const state_data = { 
                  backFlag:true,
                    successMessage: this.state.successMessage, 
                    bookId: this.props?.location?.state?.bookId, 
                    images: this.props?.location?.state?.images, 
                    total_pages: this.props?.location?.state?.total_pages, 
                    bookTitle: this.props?.location?.state?.bookTitle, 
                    token: JSON.stringify(json?.meta?.token),
                    cover_image: this.props?.location?.state?.cover_image
                }
                this.handleCreateUniqueReferalCode(json?.meta?.token);
                switch(this.props?.location?.state?.path){
                    case configJSON.pathFlipBook:
                        setTimeout(() => this.props.history.push({ pathname: configJSON.pathFlipBook, state: state_data}), 1500)
                        break
                    case configJSON.pathCustomiseCoverPage:
                        setTimeout(() => this.props.history.push({ pathname: configJSON.pathCustomiseCoverPage, state: state_data }), 1500)
                        break
                    default:
                        setTimeout(() => this.props.history.push({ pathname: configJSON.pathHomePage }), 1500)
                        break
                }
            } else {
                this.setState({ open: true })
                this.setState({ errorMessage: "Please Enter A Valid OTP", isButtonDisabled: !this.state.isButtonDisabled })
            }
        });
  }

  getEmailOTPResponse = (responseJson: any) => {
    if(responseJson && responseJson.meta) {
      this.setState({
        token: responseJson?.meta?.token,
        emailPin: responseJson?.data?.attributes?.pin,
        emailOtpSent: true,
        open: true,
        successMessage: "OTP Sent Successfully",
        isButtonDisabled: !this.state.isButtonDisabled
      }, this.handleEmailOtpTimer)
    } else {
      this.setState({
        open: true,
        errorMessage: "Email Invalid Or Already Present Please Use Another Email",
        isButtonDisabled: !this.state.isButtonDisabled
      })
    }
  }

  getMobileOTPResponse = (responseJson: any) => {
    if(responseJson && responseJson.meta) {
      this.setState({
        token: responseJson?.meta?.token,
        mobilePin: responseJson?.data?.attributes?.pin,
        mobileOtpSent: true,
        open: true,
        successMessage: "OTP Sent Successfully",
        isButtonDisabled: !this.state.isButtonDisabled
      }, this.handleMobileOtpTimer)
    } else {
      this.setState({
        open: true,
        errorMessage: "Invalid Or Unrecognized Phone Number",
        isButtonDisabled: !this.state.isButtonDisabled
      })
    }
  }

  /* HANDLE CREATE UNIQUE REFERAL CODE */
  handleCreateUniqueReferalCode = (token:string) => {
    const header = {
      "Content-Type": configJSON.createUniqueReferalCodeApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUniqueReferalAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createUniqueReferalCodeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createUniqueReferalCodeMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  /* HANDLE UPDATE UNIQUE REFERAL CODE */
  handleUpdateUniqueReferalCode = (referral_code:any) => {
    const header = {
      "Content-Type": configJSON.updateUniqueReferalCodeApiContentType,
      token: this.state?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putUniqueReferalAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateUniqueReferalCodeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ 'referral_code': referral_code})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUniqueReferalCodeMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
