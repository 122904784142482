Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.apiAdminImagesContentType = "application/json";
exports.apiAdminImagesMethodType = "GET";
exports.apiAdminImagesEndPoint = "account_block/accounts/get_dashboard_media";

exports.httpGetMethod = "GET";
exports.orderDraftGetContentType = "application/json";
exports.orderDraftGetEndPoint = "bx_block_order_management/orders/orders_list";

exports.btnExampleTitle = "CLICK ME";
exports.CTAButton = "Let's Create"

// Customizable Area End