Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorTitle = "Error";
exports.errorEmailNotValid = "Email not valid.";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.placeHolderEmailWeb = "Enter An Email"
exports.placeHolderPhoneNumber = "XXXXXXXXXX"
exports.placeHolderOTP = "Enter An OTP"
exports.pathMyOrders = "/MyOrders"
exports.pathFlipBook = "/FlipBook"
exports.pathCustomiseCoverPage = "/CustomiseCoverPage"
exports.pathHomePage = "/"

exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint ="account/accounts";
exports.apiMethodTypeAddDetail = "POST";

exports.urlGetValidations ="profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

////////////Get Email Otp//////////////
exports.getEmailOtpAPIEndPoint = "account_block/accounts/create_otp";
exports.getEmailOtpAPIMethod = "POST";
exports.getEmailOtpAPIContentType = "application/json";

////////////Verify Email Otp//////////////
exports.verifyEmailOtpAPIEndPoint = "account_block/accounts/verify_otp";
exports.verifyEmailOtpAPIMethod = "POST";
exports.verifyEmailOtpAPIContentType = "application/json";

////////////Get Mobile Otp//////////////
exports.getMobileOtpAPIEndPoint = "account_block/accounts/create_sms_otp";
exports.getMobileOtpAPIMethod = "POST";
exports.getMobileOtpAPIContentType = "application/json";

////////////Verify Mobile Otp//////////////
exports.verifyMobileOtpAPIEndPoint = "account_block/accounts/verify_sms_otp";
exports.verifyMobileOtpAPIMethod = "POST";
exports.verifyMobileOtpAPIContentType = "application/json";

////////////Resend Otp//////////////
exports.resendOtpAPiEndPoint = "account_block/accounts/resend_otp";
exports.resendOtpAPiMethod = "POST";
exports.resendOtpApiContentType = "application/json";

////////////Create Referal Code//////////////
exports.createUniqueReferalCodeEndPoint = "bx_block_referrals/referrals/create_referral";
exports.createUniqueReferalCodeMethod = "POST";
exports.createUniqueReferalCodeApiContentType = "application/json";

////////////Update Referal Code Points//////////////
exports.updateUniqueReferalCodeEndPoint = "/bx_block_referrals/referrals/:id/add_referral";
exports.updateUniqueReferalCodeMethod = "PUT";
exports.updateUniqueReferalCodeApiContentType = "application/json";

// Customizable Area End