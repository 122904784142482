// App.js - WEB
import React, { Component, Suspense, lazy } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import HomeScreen from '../../components/src/HomeScreen';
// const RouteConfig = lazy(()=>import('./route.config'))
import { Typography } from '@material-ui/core';
import useQueue from "react-use-queue";
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import ScreenshotEditor0 from '../../blocks/ScreenshotEditor0/src/ScreenshotEditor0';
import Customform from '../../blocks/customform/src/Customform';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import CfAnimationsAndTransition2 from '../../blocks/CfAnimationsAndTransition2/src/CfAnimationsAndTransition2';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import Referrals from '../../blocks/Referrals/src/Referrals';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import DiscountsOffers from '../../blocks/DiscountsOffers/src/DiscountsOffers';
import Download from '../../blocks/Download/src/Download';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import ActivityLog from '../../blocks/ActivityLog/src/ActivityLog';
import CfInterfaceForBookPreview2 from '../../blocks/CfInterfaceForBookPreview2/src/CfInterfaceForBookPreview2';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import StatisticsReports from '../../blocks/StatisticsReports/src/StatisticsReports';
import Settings2 from '../../blocks/Settings2/src/Settings2';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Payments from '../../blocks/Payments/src/Payments';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3';
import CfGooglePhotosIntegration2 from '../../blocks/CfGooglePhotosIntegration2/src/CfGooglePhotosIntegration2';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import DragDropInterface from '../../blocks/DragDropInterface/src/DragDropInterface';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import Home from '../../blocks/landingpage/src/HomeScreen.web';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import CfShiprocketIntegration2 from '../../blocks/CfShiprocketIntegration2/src/CfShiprocketIntegration2';
import OrderSummary from '../../blocks/OrderSummary/src/OrderSummary';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import EmailSignIn from '../../blocks/email-account-login/src/EmailSignIn.web';
import EmailSignUp from '../../blocks/email-account-registration/src/EmailSignUp.web';
import SelectOption from '../../blocks/Gallery/src/SelectOption.web';
import AboutUs from '../../blocks/info-page/src/AboutUs.web';
import PrivacyPolicy from '../../blocks/info-page/src/PrivacyPolicy.web';
import TermCondition from '../../blocks/info-page/src/TermCondition.web';
import ReturnRefundPolicy from '../../blocks/info-page/src/ReturnRefundPolicy.web';
import RouteConfig from './route.config';

const routeMap = {
  ScreenshotEditor0: {
    component: ScreenshotEditor0,
    path: '/ScreenshotEditor0'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  CfAnimationsAndTransition2: {
    component: CfAnimationsAndTransition2,
    path: '/CfAnimationsAndTransition2'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  DiscountsOffers: {
    component: DiscountsOffers,
    path: '/DiscountsOffers'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/ActivityLog'
  },
  CfInterfaceForBookPreview2: {
    component: CfInterfaceForBookPreview2,
    path: '/CfInterfaceForBookPreview2'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  StatisticsReports: {
    component: StatisticsReports,
    path: '/StatisticsReports'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  Analytics3: {
    component: Analytics3,
    path: '/Analytics3'
  },
  CfGooglePhotosIntegration2: {
    component: CfGooglePhotosIntegration2,
    path: '/CfGooglePhotosIntegration2'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: '/DragDropInterface'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: '/ProjectTemplates'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  CfShiprocketIntegration2: {
    component: CfShiprocketIntegration2,
    path: '/CfShiprocketIntegration2'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  EmailSignIn: {
    component: EmailSignIn,
    path: '/EmailSignIN'
  },
  EmailSignUp: {
    component: EmailSignUp,
    path: '/EmailSignUp'
  },
  SelectOption: {
    component: SelectOption,
    path: '/SelectOption'
  },
  Home: {
    component: Home,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  AboutUs: {
    component: AboutUs,
    path: '/AboutUs'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  Return_RefundPolicy: {
    component: ReturnRefundPolicy,
    path: '/ReturnRefundPolicy'
  },
  TermCondition: {
    component: TermCondition,
    path: '/TermCondition'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

const Queue = require('js-queue');
export const queue = new Queue;

const imagesHashMap = new Map();


class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    document.title = 'Whitebook.co.in'

    return (
      <View>
        <NoInternetConnection>
          {/* <TopNav /> */}
          {/* {WebRoutesGenerator({ routeMap })} */}
          {/* <ModalContainer /> */}
          {/* <Suspense fallback={<div>Loading..</div>}> */}
          <RouteConfig {...this.props} queue={queue} />
          {/* </Suspense> */}
        </NoInternetConnection>
      </View>
    );
  }
}

export default App;



const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = React.useState(true);

  // On initization set the isOnline state.
  React.useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state 
  window.addEventListener('online', () => {
    setOnline(true)
  });

  window.addEventListener('offline', () => {
    setOnline(false)
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return (
      props.children
    )
  } else {
    return (
      <div style={{ display: 'grid', placeItems: 'center', backgroundColor: '#29BDBD', height: '100vh', padding: '10px' }}>
        <Typography variant='h5' style={{ color: '#fff' }}>No Internet connection found. Check you connection or try again.</Typography>
      </div>
    )
  }
}
