import React from 'react';
// Customizable Area Start

import TermConditionController from './TermConditionController.web';
import { Grid, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import "./CMS.css";
import Loader from '../../../components/src/Loader.web';

class TermCondition extends TermConditionController {

    render() {

        const htmlString = `${this.state.termsAndCondition}`

        const { closeVisible } = this.props;
        return (
            <Grid container direction="row" justifyContent="center" style={{ marginTop: "0px", paddingTop: "54px", fontFamily: "inherit" }}>
                <Grid item xs={12} lg={7} md={12} sm={12} style={{ marginBottom: "0px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", background: "#ffffff", position: "fixed", top: 0, zIndex: 2, padding: "15px 0" }}>
                    {closeVisible &&
                        <div style={{ fontSize: '1.2em', cursor: 'pointer', position: "absolute", left: "0", marginLeft: "15px" }} onClick={() => { this.props.history.push("/Menu") }} >
                            <ArrowBackIos />
                        </div>}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ margin: "0", fontSize: "20px", fontFamily: "inherit" }}>Term &amp; Conditions</h3>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} lg={7} md={12} sm={12}>
                    {this.state.termsAndCondition === null ? <Loader loading={true}/> : <div className="ql-editor"><div dangerouslySetInnerHTML={{ __html: htmlString }} /></div>}
                </Grid>
                {/* <Grid item xs={12} lg={7} md={12} sm={12}>
                    <h1 style={{ fontSize: "1.3em" }}>General Terms</h1>
                    <p style={{ textAlign: "justify", fontSize: "1em" }}>
                        Welcome  to mation, resources, services, products  and tools we provide,
                        you understand and adhere to the folowing terms & conditions as stated to the following terms and
                        condition as  stated in this policy .(here after  referred to as user aggrement)
                        ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                        policy content section below for more information).
                    </p>
                    <p>
                        This Aggrement is in effect as of June 25,2012
                    </p>
                    <p style={{ textAlign: "justify", fontSize: "1em" }}>
                        We  resere the right to changethis user Aggrement from time to time with  notice.
                        You  acknowledge and agree that is your responsibility to review this User Aggrement
                        periodically to familarize yourself with any modifications.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores itaque doloribus tempora, facere consequuntur neque doloremque impedit culpa nesciunt exercitationem minus quibusdam porro vero quae dolor vitae quis veritatis unde fugiat voluptates, ullam iste. Aperiam iure laboriosam doloribus nesciunt, provident officia. Amet officia necessitatibus eligendi nostrum, ratione pariatur omnis hic?
                    </p>
                </Grid>
                <Grid item xs={12} lg={7} md={12} sm={12}>
                    <h1 style={{ fontSize: "1.3em" }}>Distribution</h1>
                    <p style={{ textAlign: "justify", fontSize: "1em" }}>
                        Welcome  to mation , resources ,services , products  and tools we provide ,
                        you understand and adhere to the folowing terms & conditions as stated to the following terms and
                        condition as  stated in this policy .(here after  referred to as user aggrement)
                        ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                        policy content section below for more information).
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores animi ipsam fuga exercitationem quia suscipit distinctio necessitatibus laboriosam iure quasi? Quaerat quisquam quasi tempora excepturi odit perspiciatis laboriosam beatae, harum voluptate eius incidunt deleniti error reiciendis in. Dolorem tenetur dignissimos unde velit porro quos consequatur, necessitatibus, eligendi optio reiciendis cumque.
                    </p>
                </Grid>
                <Grid item xs={12} lg={7} md={12} sm={12}>
                    <h1 style={{ fontSize: "1.3em" }}>OwnerShip Splits</h1>
                    <p style={{ textAlign: "justify", fontSize: "1em" }}>
                        Welcome  to mation , resources ,services , products  and tools we provide ,
                        you understand and adhere to the folowing terms & conditions as stated to the following terms and
                        condition as  stated in this policy .(here after  referred to as user aggrement)
                        ,alongwith the terms and conditions as shared in pur  Privacy Policy(please refer  to the privacy
                        policy content section below for more information).
                    </p>
                </Grid> */}
            </Grid>
        )
    }
}

export default withRouter(TermCondition);
// Customizable Area End