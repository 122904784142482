export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editButton = require("../assets/editButton.png");
export const colorButton = require("../assets/colorButton.jfif");
export const coverpageButton = require("../assets/coverpageButton.png");
export const titlepageButton = require("../assets/titlepageButton.png");
export const backIcon = require("../assets/backIcon.png");
export const galleryLogo=require("../assets/galleryPhotoLogo.png");
export const googlePhotoLogo=require("../assets/googlePhotoLogo.png");
export const blackLogo = require("../assets/logo black.png");
export const whiteLogo = require("../assets/logo white.png");
export const blueLogo = require("../assets/blueLogo.svg");