import React from "react"
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@material-ui/icons/Close";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import Slide from '@material-ui/core/Slide';

const Popup: any = (props: any) => {

    return (
        <>
            <Snackbar
                open={props.flag}
                // TransitionComponent={(props:any) => <Slide {...props} direction="left" />}
                // transitionDuration={500}
                autoHideDuration={8000}
                onClose={props.handleClose}
                style={props.style}
                className={props.className}
                key="popup"
            >
                <SnackbarContent
                    style={{
                        backgroundColor:
                                props.massage === "Account not activated" ||
                                props.massage === "Account Not Found Or Not Activated" ||
                                props.massage === "Please Enter A Valid OTP" ||
                                props.massage === "Email Invalid Or Already Present Please Use Another Email" ||
                                props.massage === "Invalid Or Unrecognized Phone Number" ||
                                props.massage === 'Please Enter A Valid Phone Number' ||
                                props.massage === 'Please Enter A Valid Email!'
                                ? "red" : '#8da4ee',
                        color: "#fff", display: "flex", justifyContent: "space-between", alignItems: "center",
                    }}
                    message={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <span style={{ display: "flex", justifyContent: "start", alignItems: "center", textAlign: "center" }}>
                                {props.massage === "low resolution image" ||
                                    props.massage === "Low Resolution: Printing may be affected" ?
                                    <WarningRoundedIcon style={{ color: "#e8e53a", marginRight: 16 }} /> :
                                    props.massage === "Click to Rotate Image" ?
                                        <CachedOutlinedIcon /> : ""}
                                &nbsp;{props.massage}
                            </span>
                            <IconButton style={{ position: "relative", right: "0", marginLeft: "5px" }} size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    }
                />
            </Snackbar>
        </>
    )
}
export default Popup