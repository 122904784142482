import React, { PureComponent, Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import Loader from "../../components/src/Loader.web";


const Landingpage = lazy(() => import("../../blocks/landingpage/src/LandingPage.web"))
const Menu = lazy(() => import("../../blocks/landingpage/src/Menu.web"))
const Account = lazy(() => import("../../blocks/email-account-login/src/Account.web"))
const EmailSignIn = lazy(() => import("../../blocks/email-account-login/src/EmailSignIn.web"))
const EmailSignUp = lazy(() => import("../../blocks/email-account-registration/src/EmailSignUp.web"))
const AboutUs = lazy(() => import("../../blocks/info-page/src/AboutUs.web"))
const PrivacyPolicy = lazy(() => import("../../blocks/info-page/src/PrivacyPolicy.web"))
const TermCondition = lazy(() => import("../../blocks/info-page/src/TermCondition.web"))
const ReturnRefundPolicy = lazy(() => import("../../blocks/info-page/src/ReturnRefundPolicy.web"))
const HelpCenter = lazy(() => import("../../blocks/helpcentre/src/HelpCenter.web"))
const Support = lazy(() => import("../../blocks/helpcentre/src/Support.web"))
const FAQs = lazy(() => import("../../blocks/helpcentre/src/FAQs.web"))
const SelectOptionFirst = lazy(() => import("../../blocks/Gallery/src/SelectOptionFirst.web"))
const SelectOption = lazy(() => import("../../blocks/Gallery/src/SelectOption.web"))
const GalleryPhoto = lazy(() => import("../../blocks/Gallery/src/GalleryPhoto.web"))
const FlipBook = lazy(() => import("../../blocks/Gallery/src/FlipBook.web"))
const BookPreview = lazy(() => import("../../blocks/Gallery/src/BookPreview.web"))
const CustomiseCoverPage = lazy(() => import("../../blocks/CfAnimationsAndTransition2/src/CustomiseCoverPage.web"))
const EditCover = lazy(() => import("../../blocks/CfAnimationsAndTransition2/src/EditCover.web"))
const TitleEdit = lazy(() => import("../../blocks/CfAnimationsAndTransition2/src/TitleEdit.web"))
const ColorEdit = lazy(() => import("../../blocks/CfAnimationsAndTransition2/src/ColorEdit.web"))
const TitleLayoutEdit = lazy(() => import("../../blocks/CfAnimationsAndTransition2/src/TitleLayoutEdit.web"))
const NewCoverEdit = lazy(() => import("../../blocks/CfAnimationsAndTransition2/src/NewCoverEdit.web"))
const CustomizeBook = lazy(() => import("../../blocks/customisableusersubscriptions/src/CustomizeBook.web"))
const CheckoutFlow = lazy(() => import("../../blocks/ordermanagement/src/Checkout.web"))
const MyOrders = lazy(() => import("../../blocks/ordermanagement/src/MyOrders.web"))
const OrderSummary = lazy(() => import("../../blocks/OrderSummary/src/OrderSummary.web"))
const Congratulations = lazy(() => import("../../blocks/ordermanagement/src/Congratulations.web"))
const CustomiseBookPages = lazy(() => import('../../blocks/CfAnimationsAndTransition2/src/CustomiseBookPages.web'))
const MyOrderDetails = lazy(() => import("../../blocks/ordermanagement/src/MyOrderDetails.web"))
// const AskToContribute = lazy(() => import("../../blocks/Gallery/src/AskToContribute.web"))
// const ContributeImages = lazy(() => import("../../blocks/Gallery/src/ContributeImages.web"))
// const ShowContributedImages = lazy(() => import("../../blocks/Gallery/src/ShowContributedImages.web"))
const ReferFriends = lazy(() => import('../../blocks/Referrals/src/ReferFriends.web'))
const BestStories = lazy(() => import("../../blocks/catalogue/src/Stories.web"))
const Blogs = lazy(() => import("../../blocks/catalogue/src/Blogs.web"))
const SingleBlog = lazy(() => import("../../blocks/catalogue/src/SingleBlog.web"))
const PageNotFound = lazy(() => import('../../blocks/landingpage/src/PageNotFound.web'))
const CheckoutAddress = lazy(() => import("../../blocks/ordermanagement/src/CheckoutAddress.web"))
const CheckoutDetails = lazy(() => import("../../blocks/ordermanagement/src/CheckoutDetails.web"))
const ScrollToTop = lazy(() => import('../../components/src/ScrollToTop'));
const MyAddresses = lazy(() => import('../../blocks/ordermanagement/src/MyAddresses.web'))
const UpdateMyAddress = lazy(() => import('../../blocks/ordermanagement/src/UpdateMyAddress.web'))
const AddNewAddress = lazy(() => import('../../blocks/ordermanagement/src/AddNewAddress.web'))

// import Landingpage from "../../blocks/landingpage/src/LandingPage.web";
// import Menu from "../../blocks/landingpage/src/Menu.web";
// import Account from "../../blocks/email-account-login/src/Account.web";
// import EmailSignIn from "../../blocks/email-account-login/src/EmailSignIn.web";
// import EmailSignUp from "../../blocks/email-account-registration/src/EmailSignUp.web";
// import AboutUs from "../../blocks/info-page/src/AboutUs.web"
// import PrivacyPolicy from "../../blocks/info-page/src/PrivacyPolicy.web";
// import TermCondition from "../../blocks/info-page/src/TermCondition.web";
// import ReturnRefundPolicy from "../../blocks/info-page/src/ReturnRefundPolicy.web";
// import HelpCenter from "../../blocks/helpcentre/src/HelpCenter.web";
// import Support from "../../blocks/helpcentre/src/Support.web";
// import FAQs from "../../blocks/helpcentre/src/FAQs.web"
// import SelectOptionFirst from "../../blocks/Gallery/src/SelectOptionFirst.web";
// import SelectOption from "../../blocks/Gallery/src/SelectOption.web";
// import GalleryPhoto from "../../blocks/Gallery/src/GalleryPhoto.web";
// import FlipBook from "../../blocks/Gallery/src/FlipBook.web";
// import BookPreview from "../../blocks/Gallery/src/BookPreview.web"
// import CustomiseCoverPage from "../../blocks/CfAnimationsAndTransition2/src/CustomiseCoverPage.web";
// import EditCover from "../../blocks/CfAnimationsAndTransition2/src/EditCover.web";
// import TitleEdit from "../../blocks/CfAnimationsAndTransition2/src/TitleEdit.web"
// import ColorEdit from "../../blocks/CfAnimationsAndTransition2/src/ColorEdit.web"
// import TitleLayoutEdit from "../../blocks/CfAnimationsAndTransition2/src/TitleLayoutEdit.web"
// import NewCoverEdit from "../../blocks/CfAnimationsAndTransition2/src/NewCoverEdit.web"
// import CustomizeBook from "../../blocks/customisableusersubscriptions/src/CustomizeBook.web";
// import CheckoutFlow from "../../blocks/ordermanagement/src/Checkout.web";
// import MyOrders from "../../blocks/ordermanagement/src/MyOrders.web";
// import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary.web";
// import Congratulations from "../../blocks/ordermanagement/src/Congratulations.web"
// import CustomiseBookPages from '../../blocks/CfAnimationsAndTransition2/src/CustomiseBookPages.web';
// import MyOrderDetails from "../../blocks/ordermanagement/src/MyOrderDetails.web";
// import AskToContribute from "../../blocks/Gallery/src/AskToContribute.web"
// import ContributeImages from "../../blocks/Gallery/src/ContributeImages.web"
// import ShowContributedImages from "../../blocks/Gallery/src/ShowContributedImages.web"
// import ReferFriends from '../../blocks/Referrals/src/ReferFriends.web'
// import BestStories from "../../blocks/catalogue/src/Stories.web";
// import Blogs from "../../blocks/catalogue/src/Blogs.web";
// import SingleBlog from "../../blocks/catalogue/src/SingleBlog.web";
// import { PageNotFound } from '../../blocks/landingpage/src/PageNotFound.web'
// import CheckoutAddress from '../../blocks/ordermanagement/src/CheckoutAddress.web';
// import CheckoutDetails from '../../blocks/ordermanagement/src/CheckoutDetails.web';


class RouteConfig extends PureComponent {

  render() {

    return (
      <Suspense fallback={<Loader loading={true} />}>
        <Router>
          <>
            <ScrollToTop />
            <Switch>

              <Route path="/" exact>
                <Landingpage />
              </Route>


              <Route path="/Menu" exact>
                <Menu />
              </Route>


              <Route path="/Account" exact>
                <Account />
              </Route>


              <Route path="/EmailSignIn" exact>
                <EmailSignIn />
              </Route>


              <Route path="/EmailSignUp" exact>
                <EmailSignUp />
              </Route>


              <Route path="/AboutUs" exact>
                <AboutUs />
              </Route>


              <Route path="/PrivacyPolicy" exact>
                <PrivacyPolicy closeVisible={true} />
              </Route>


              <Route path="/TermCondition" exact>
                <TermCondition closeVisible={true} />
              </Route>


              <Route path="/ReturnRefundPolicy" exact>
                <ReturnRefundPolicy />
              </Route>


              <Route path="/HelpCenter" exact>
                <HelpCenter />
              </Route>


              <Route path="/Support" exact>
                <Support />
              </Route>


              <Route path="/FAQs" exact>
                <FAQs />
              </Route>


              <Route path="/SelectOption" exact>
                <SelectOption />
              </Route>


              <Route path="/SelectOptionFirst" exact>
                <SelectOptionFirst />
              </Route>


              <Route path="/GalleryPhoto" exact component={(props) => (<GalleryPhoto location={props.location} {...this.props} />)} />


              <Route path="/FlipBook" exact>
                <FlipBook />
              </Route>


              <Route path="/BookPreview/:id" >
                <BookPreview />
              </Route>


              <Route path="/CustomiseCoverPage" exact component={(props) => (<CustomiseCoverPage location={props.location} {...this.props} />)} />


              <Route path="/EditCover" exact>
                <EditCover />
              </Route>


              <Route path="/ColorEdit" exact>
                <ColorEdit />
              </Route>


              <Route path="/TitleLayoutEdit" exact>
                <TitleLayoutEdit />
              </Route>


              <Route path="/TitleEdit" exact>
                <TitleEdit />
              </Route>


              <Route path="/NewCoverEdit" exact>
                <NewCoverEdit />
              </Route>


              <Route path="/CustomizeBook" exact>
                <CustomizeBook />
              </Route>


              <Route path="/Checkout/address" exact>
                <CheckoutAddress />
              </Route>

              <Route path="/myaddresses" exact>
                <MyAddresses />
              </Route>

              <Route path="/newaddress" exact>
                <AddNewAddress />
              </Route>

              <Route path="/updatemyaddress" exact>
                <UpdateMyAddress />
              </Route>
              
              <Route path="/Checkout/billing" exact>
                <CheckoutDetails />
              </Route>


              <Route path="/Checkout/payment" exact>
                <CheckoutFlow />
              </Route>


              <Route path="/OrderSummary" exact>
                <OrderSummary />
              </Route>


              <Route path="/MyOrders" exact>
                <MyOrders />
              </Route>


              <Route path="/MyOrder/:id" exact>
                <MyOrderDetails />
              </Route>


              <Route path='/CustomiseBookPages' exact>
                <CustomiseBookPages />
              </Route>


              <Route path="/Congratulations" exact>
                <Congratulations />
              </Route>


              {/* <Route path='/AskToContribute' exact>
                <AskToContribute />
              </Route> */}


              {/* <Route path="/contributedImages" exact>
                <ShowContributedImages />
              </Route> */}


              <Route path='/Refer' exact>
                <ReferFriends />
              </Route>


              {/* Make Below route at lowest */}
              {/*
          <Route path="/contribute/:link/:id" >
           <ContributeImages/>
         </Route> 
         */}


              <Route path="/stories" exact>
                <BestStories />
              </Route>


              <Route path="/blogs" exact>
                <Blogs />
              </Route>


              <Route path="/blog/:id" exact>
                <SingleBlog />
              </Route>


              <Route path="/404">
                <PageNotFound />
              </Route>


              <Redirect to="/404" />

            </Switch>
          </>
        </Router>
      </Suspense>
    );
  }
}
export default RouteConfig;
