export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logoImg = require("../assets/logo black.png");
export const landingMobImg = require("../assets/landing page image.png");
export const reviewImg = require("../assets/reviews.png");
export const footerImg = require("../assets/bottom image.png");
export const footerLogo = require("../assets/logo white.png");
export const twitterImg= require('../assets/twitter.png');
export const instImg = require('../assets/instagram.png');
export const bottomNav = require('../assets/bottom navbar1.png');
export const star = require('../assets/star.png');
export const qualityMark = require('../assets/Quality mark.png');
export const bookImg = require('../assets/Book image.png');
export const yellowStar= require('../assets/star gif.png');
export const aboutUs = require('../assets/about us.png');
export const rightArrow = require('../assets/right-arrow.png');
export const menu =require('../assets/menu.png');
export const plus = require('../assets/plus.png');
export const fbImg=require('../assets/facebook.png');
