Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
exports.httDeleteMethod = "DELETE"
exports.httpPatchMethod = "PATCH"

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "order_management/orders";
exports.rateAPiEndPoint = "catalogue/reviews";

exports.userDetailsContentType = "application/json";
exports.userDetailsGetEndPoint = "account_block/accounts/get_user_details";
exports.editUserAddressPutEndPoint = "bx_block_address/addresses/update_address"

exports.addressPostContentType = "application/json";
exports.addressPostEndPoint = "bx_block_address/addresses";
exports.addressPutEndPoint = "bx_block_address/addresses/update_address";
exports.shareWithFamily2xOrderEndpoint = "bx_block_ordersummary/order_summary/order_x2";

exports.detailsGetContentType = "application/json";
exports.detailsGetEndPoint = "bx_block_ordersummary/order_summary/get_order_details";

exports.GetAddressByOrderIDContentType = "application/json";
exports.GetAddressByOrderIDEndPoint = "bx_block_address/addresses/get_address";

exports.rewardsGetContentType = "application/json";
exports.rewardsGetEndPoint = "bx_block_discountsoffers/offers";

exports.applyCuponPostContentType = "application/json";
exports.applyCuponPostEndPoint = "bx_block_order_management/orders/apply_coupon";

exports.removeCuponPostContentType = "application/json";
exports.removeCuponPostEndPoint = "bx_block_order_management/orders/remove_coupon";

exports.quantityPutContentType = "application/json";
exports.quantityPutEndPoint = "bx_block_ordersummary/order_summary/update_quantity";

exports.giftNotePutContentType = "application/json";
exports.giftNotePutPutEndPoint = "bx_block_ordersummary/order_summary/add_gift_note";

exports.razorpayCreateOrderContentType = "application/json";
exports.razorpayCreateOrderEndPoint = "bx_block_payment_razorpay/razorpays";

exports.orderDraftGetContentType = "application/json";
exports.orderDraftGetEndPoint = "bx_block_order_management/orders/orders_list";

exports.deleteApiContentType = "application/json";
exports.deleteDraftAPiEndPoint = "bx_block_order_management/orders/draft_order_delete";

exports.orderInProgressGetContentType = "application/json";
exports.orderInProgressGetEndPoint = "bx_block_order_management/orders/orders_list";

exports.orderCompletedGetContentType = "application/json";
exports.orderCompletedGetEndPoint = "bx_block_order_management/orders/orders_list";

exports.shiprocketPostContentType = "application/json";
exports.shiprocketPostEndPoint = "bx_block_order_management/shiprocket";

exports.myorderdetailsGetCotentType = "application/json";
exports.myorderdetailsGetEndPoint = "bx_block_order_management/orders/";

exports.myAddressGetCotentType = "application/json";
exports.myAddressGetEndPoint = "bx_block_address/addresses/get_address";

exports.MyOrderTrackingType = "application/json";
exports.MyOrderTrackingEndPoint = "bx_block_order_management/shiprocket/track_order";

exports.getInvoiceDetailsType = "application/json";
exports.getInvoiceDetailsEndPoint = "bx_block_order_management/shiprocket/generate_invoice";

exports.apiGetImageLimitEndPoint = "bx_block_book/books/get_image_limit"
exports.apiGetImageLimitContentType = "application/json";

exports.deleteMyAddressesContentType = "application/json";
exports.deleteMyAddressesEndPoint = "bx_block_address/addresses";

exports.updateMyAddressesContentType = "application/json";
exports.updateMyAddressesEndPoint = "bx_block_address/addresses";

exports.addAddressesContentType = "application/json";
exports.addAddressesEndPoint = "bx_block_address/addresses/address_create";

exports.RazorPayTestDescription = "Test Transaction";
exports.RazorPayName = "Whitebook";
exports.RazorPayCurrency = "INR";

exports.RazorPayTestAPIKey = "rzp_test_kHoHXmbRXkg8HH";
exports.RazorPayLiveAPIKey = "rzp_live_sy9wteGyG8LATh";

exports.prodFrontURL = "https://m.whitebook.co.in/";

// Customizable Area End