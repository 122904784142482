// Customizable Area Start
import React from 'react';
import LandingPageController from "./LandingPageController.web";
import { footerImg, footerLogo, instImg, twitterImg, fbImg, bottomNav, FooterBottom, SeeTheMagicChaos } from './assets';
// import { Grid, Link } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

export default class Footer extends LandingPageController {
    render() {

        return (
            // this.state.adminImages && 
            <Grid container >
                {/* <Grid item xs={12}>
                    <img src={this.state.adminImages[22]?.attributes?.media_file} loading="lazy" style={{marginBottom: "-8px"}}  className="responsive"   />
                </Grid> */}

                <Grid item xs={12} style={styles.footerBg}>
                    
                    {/* <div className="homeBacks"   style={{ textAlign: 'center', color: 'white', paddingTop: '20px' }}>
                        <img src={this.state.adminImages[23]?.attributes?.media_file} loading="lazy" width="250" style={{padding:"20px 0px"}} />
                        <div style={{ lineHeight: '0.8', padding: "0 16px" }}>
                            <h4 style={{ fontWeight: "600", lineHeight: "1.5", margin: "0 0 1em", fontSize: "18px", color: "#000000" }}>Come be a part of our  Instagram community - It's Funn!</h4>
                        </div>
                    </div> */}

                    <Grid container justifyContent="center" alignItems="center" style={{padding:"20px 0px"}}>
                        <Grid item xs={12} >
                            <div style={{ textAlign: "center" }}>
                                {/* <img src={fbImg} width="30" style={{paddingLeft:'10px'}} /> */}
                                <a href="https://www.instagram.com/realmoms_by_whitebook/" target="_blank">
                                <img src={instImg} loading="lazy" width="40" style={{paddingLeft:'10px'}} />
                                    {/* <img src={this.state?.adminImages[24]?.attributes?.media_file} width="40" style={{paddingLeft:'10px'}} /> */}
                                </a>
                                {/* <img src={twitterImg} width="30" style={{paddingLeft:'10px'}} /> */}
                            </div>
                        </Grid>
                    </Grid>

                    <div>
                        <Accordion style={styles.accordionBg}>
                            <AccordionSummary style={styles.accordionSummary} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography style={styles.accordionTitle}>Contact Us</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <h3 style={{ fontWeight: "400", lineHeight: "1.5", margin: "5px 0 0" }}><a style={{ textDecoration: "none" }} className="txt-blue" href="tel:+916364182702">+91 6364-182702</a></h3>
                                    <h4 style={{ fontWeight: "500", lineHeight: "1.5", fontSize: "18px", marginTop: "10px", marginBottom: "0" }}>Site No.191, SLV Complex, 2nd Sector , 27th Main, 19th Cross, HSR Layout, Bangalore - 560102</h4>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="footerAccordion" style={styles.accordionBg}>
                            <AccordionSummary style={styles.accordionSummary} aria-controls="panel2a-content" id="panel2a-header">
                                <Typography style={styles.accordionTitle}>Support</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <h3 style={{ fontWeight: "400", lineHeight: "1.5", margin: "5px 0 0" }}><a style={{ textDecoration: "none" }} className="txt-blue" href="mailto:customercare@whitebook.co.in">customercare@whitebook.co.in</a></h3>
                                    <h4 style={{ fontWeight: "500", lineHeight: "1.5", fontSize: "18px", marginTop: "10px", marginBottom: "0" }}>Live Support Hours Monday to Saturday : 10 am - 6 PM IST ( Sunday : Closed )</h4>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Grid container style={{ padding: "0px 16px", margin: "14px 0" }}>
                            <Grid item xs={12}>
                                <Typography style={styles.accordionTitle}>
                                    <Link href="/FAQs" color="inherit" style={{ display: "block" }}>FAQ's</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container style={{padding:"20px 16px", textAlign: "center", color: "#ffffff"}} direction="row" justifyContent="space-between" >

                        <Grid item xs={12} style={{padding:"20px 0px"}}>
                            <span style={{ color: "#000000", fontWeight: "500" }}>Copyright &copy; Varahome Private Limited</span>
                        </Grid>

                        <Grid item xs={6}>
                            <span style={{cursor:'pointer', color: "#000000", fontSize: "14px"}} onClick={()=>{window.location.href='TermCondition'}}>Terms &amp; Conditions</span>
                        </Grid>

                        <Grid item xs={6}>
                            <span style={{cursor:'pointer', color: "#000000", fontSize: "14px"}} onClick={()=>{window.location.href='PrivacyPolicy'}}>Privacy Policy</span>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {/* <img src={bottomNav} width="100%" /> */}
                    <div style={styles.footerPalatte}>
                        <div style={{width: "100%", backgroundColor: "#E7EDFC"}}></div>
                        <div style={{width: "100%", backgroundColor: "#D0DBF8"}}></div>
                        <div style={{width: "100%", backgroundColor: "#BECDF9"}}></div>
                        <div style={{width: "100%", backgroundColor: "#A9BDF9"}}></div>
                        <div style={{width: "100%", backgroundColor: "#87A1ED"}}></div>
                    </div>
                </Grid>

            </Grid>
        )
    }
}

const styles = {
    footerBg: {
        // background: "linear-gradient(180deg, rgba(41,189,189,1) 0%, rgba(135,161,237,1) 50%)",
        // background: "linear-gradient(rgba(41, 189, 189,0.5) 0%, rgba(135, 161, 237, 0.6) 50%)"
        background: "#e5e0e0"
    },
    footerPalatte: {
        display: "flex",
        height: "20px",
        width: "100%",
        flexDirection: "row" as "row",
        justifyContent: "space-evenly" as "space-evenly"
    },
    accordionTitle: {
        fontSize: "18px",
        fontWeight: "600",
        color: "#000000"
    },
    accordionBg: {
        backgroundColor: "transparent",
        boxShadow: "none"
    },
    accordionSummary: {
        margin: "0"
    }
}
// Customizable Area End