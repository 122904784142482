Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Referrals";
exports.labelBodyText = "Referrals Body";

exports.apiGetUserDetailsContentType = "application/json"
exports.apiGetUserDetailsMethodType = "GET"
exports.apiGetUserDetailsEndPoint = "bx_block_referrals/referrals/:id/get_user_referral"

exports.btnExampleTitle = "CLICK ME";

// Customizable Area End