// Customizable Area Start 
import ReactGA from "react-ga4";
const configJSON = require("./config");

const GoogleAnalytics: any = async () => {

  let base_url = window.location.origin
  let dev_url = configJSON.devFrontURL
  let local_url = configJSON.localFrontURL
  let stage_url = configJSON.stageFrontURL
  let prod_url = configJSON.ProdFrontURL

  if (base_url === dev_url) {
    ReactGA.initialize(configJSON.dev_trackingID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
  if (base_url === stage_url) {
    ReactGA.initialize(configJSON.stage_trackingID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
  if (base_url === prod_url) {
    ReactGA.initialize(configJSON.prod_trackingID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
}

export default GoogleAnalytics
// Customizable Area End