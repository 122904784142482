import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import GoogleAnalytics from "../../../components/src/GoogleAnalytics.web"
const configJSON = require("./config");
// Customizable Area End

export interface Props extends RouteComponentProps<any> {

  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  history: any;
  location: any,
  RouteComponentProps: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  openMenu: boolean;
  authToken: string;
  adminImages: any;
  playCarousel: boolean;
  open: boolean,
  errorOpen: boolean,
  vertical: any,
  horizontal: any,
  successMessage: any,
  errorMessage: any,
  isLoading: boolean,
  selectPage: boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  apiAdminImagesCallId: string = "";
  getDraftOrdersCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      openMenu: false,
      authToken: "",
      adminImages: null,
      playCarousel: true,
      open: false,
      errorOpen: false,
      vertical: 'top',
      horizontal: 'center',
      successMessage: null,
      errorMessage: null,
      isLoading: true,
      selectPage: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    GoogleAnalytics()
    let authTokenData = await StorageProvider.get('authToken')
    if (authTokenData) {
      authTokenData = JSON.parse(authTokenData);
      this.setState({
        authToken: authTokenData,
      });
    }
    

    if (this.props?.location?.state?.open === true) {
      this.setState({ open: true })
    }
    console.log("state...", this.props?.location?.state)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId === this.apiAdminImagesCallId) {
        if (responseJson) {
          console.log("Response : ", responseJson?.data?.data);
          this.setState({ adminImages: responseJson?.data?.data });
        }
      } else {
        console.log("Error: ", errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  goToOtherPage = (route: string) => {
    window.location.href = route;
  }
  handleClose = () => {
    this.setState({ open: false })
  }

  flipBox = (e: any) => {
    e.preventDefault();
    if (e.target.parentElement.classList.contains('flipActive')) {
      e.target.parentElement.classList.remove("flipActive")
    } else {
      e.target.parentElement.classList.add("flipActive")
    }
  }
  // Customizable Area End
}